// File: client/src/components/Auth/LandingPage.js

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { login, registerUser } from '../../store/authSlice.js';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Box,
  Heading,
  Button,
  FormControl,
  FormLabel,
  Input,
  Text,
  Card,
  CardBody,
  useToast
} from '@chakra-ui/react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import axios from '../../api/axiosInstance.js';

/**
 * LandingPage serves as both the Login and Registration form,
 * toggling via isRegister. If the user is already authenticated,
 * they are immediately redirected to /dashboard.
 */
function LandingPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();

  const { loading, error, user, token } = useSelector((state) => state.auth);
  const [isRegister, setIsRegister] = React.useState(false);
  const [csrfToken, setCsrfToken] = React.useState('');
  const [successMsg, setSuccessMsg] = React.useState('');
  const [showResendVerification, setShowResendVerification] = React.useState(false);

  const formikRef = React.useRef(null);

  useEffect(() => {
    if (user && token) {
      navigate('/dashboard');
    }
  }, [user, token, navigate]);

  useEffect(() => {
    const fetchCsrfToken = async () => {
      try {
        const response = await axios.get('/csrf');
        setCsrfToken(response.data.csrfToken);
      } catch (error) {
        console.error('Error fetching CSRF token', error);
      }
    };
    fetchCsrfToken();
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get('verified') === '1') {
      setSuccessMsg('Verification successful! Please log in.');
      setIsRegister(false);
    }
  }, [location.search]);

  /**
   * Updated validation to enforce password >= 8 if registering
   */
  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .trim()
      .matches(/^\S+$/, 'No spaces allowed')
      .required('Required'),
    password: Yup.string().when('isRegister', {
      is: true,
      then: (schema) => schema
        .required('Required')
        .min(8, 'Password must be at least 8 characters'),
      otherwise: (schema) => schema.required('Required')
    }),
    email: Yup.string().when('isRegister', {
      is: true,
      then: (schema) => schema.email('Invalid email').required('Required'),
      otherwise: (schema) => schema.notRequired()
    })
  });

  const handleSubmit = async (values, actions) => {
    try {
      if (isRegister) {
        if (values.password.length < 8) {
          toast({
            title: 'Registration error',
            description: 'Password must be at least 8 characters',
            status: 'error',
            duration: 4000,
            isClosable: true
          });
          actions.setSubmitting(false);
          return;
        }
        const result = await dispatch(registerUser(values));
        if (result.payload && result.payload.success) {
          toast({
            title: 'Registration successful!',
            description: 'Please check your email for verification.',
            status: 'success',
            duration: 4000,
            isClosable: true
          });
          setSuccessMsg(
            'Registration successful! Check your email for a verification link.'
          );
        } else if (result.payload && result.payload.error) {
          toast({
            title: 'Registration error',
            description: result.payload.error,
            status: 'error',
            duration: 4000,
            isClosable: true
          });
        }
      } else {
        const result = await dispatch(login(values));
        if (result.payload && result.payload.success) {
          toast({
            title: 'Login successful!',
            status: 'success',
            duration: 3000,
            isClosable: true
          });
          navigate('/dashboard');
        } else if (
          result.payload &&
          result.payload.error === 'Email not verified'
        ) {
          toast({
            title: 'Email not verified',
            description: 'Please verify your email or resend the verification link.',
            status: 'warning',
            duration: 4000,
            isClosable: true
          });
          setShowResendVerification(true);
        } else if (result.payload && result.payload.error) {
          toast({
            title: 'Login error',
            description: result.payload.error,
            status: 'error',
            duration: 4000,
            isClosable: true
          });
        }
      }
    } catch (err) {
      console.error(err);
    }
    actions.setSubmitting(false);
  };

  const handleResendVerification = async () => {
    if (!formikRef.current) return;
    const formValues = formikRef.current.values;
    if (!formValues.email) {
      toast({
        title: 'No email provided',
        status: 'error',
        duration: 3000,
        isClosable: true
      });
      return;
    }
    try {
      const resp = await axios.post('/auth/resend-verification', {
        email: formValues.email
      });
      if (resp.data.success) {
        toast({
          title: 'Verification email resent!',
          status: 'success',
          duration: 4000,
          isClosable: true
        });
      } else {
        toast({
          title: 'Error',
          description: resp.data.error || 'Unknown resend error',
          status: 'error',
          duration: 4000,
          isClosable: true
        });
      }
    } catch (err) {
      console.error(err);
      toast({
        title: 'Error sending verification',
        description: err.response?.data?.error || err.message,
        status: 'error',
        duration: 4000,
        isClosable: true
      });
    }
  };

  const initialValues = { username: '', password: '', email: '', isRegister };

  return (
    <Box maxW="400px" mt="3rem" mx="auto" textAlign="center">
      <Card bg="white" boxShadow="md">
        <CardBody>
          <Heading as="h2" mb={4}>
            {isRegister ? 'Register' : 'Login'}
          </Heading>
          {successMsg && <Text color="green.500" mb={2}>{successMsg}</Text>}
          {error && <Text color="red.500" mb={2}>{error}</Text>}

          <Formik
            innerRef={formikRef}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, isSubmitting, setFieldValue }) => (
              <Form>
                {/* Trick: keep track of isRegister in form values so Yup can enforce min 8 chars */}
                <Field type="hidden" name="isRegister" value={isRegister} />

                {isRegister && (
                  <FormControl
                    mb={3}
                    isInvalid={errors.email && touched.email}
                    isRequired
                  >
                    <FormLabel>Email</FormLabel>
                    <Field as={Input} type="email" name="email" placeholder="Email" />
                    {errors.email && touched.email && (
                      <Text color="red.500" fontSize="sm">
                        {errors.email}
                      </Text>
                    )}
                  </FormControl>
                )}

                <FormControl
                  mb={3}
                  isInvalid={errors.username && touched.username}
                  isRequired
                >
                  <FormLabel>Username (No spaces allowed)</FormLabel>
                  <Field as={Input} type="text" name="username" placeholder="Username" />
                  {errors.username && touched.username && (
                    <Text color="red.500" fontSize="sm">
                      {errors.username}
                    </Text>
                  )}
                </FormControl>

                <FormControl
                  mb={3}
                  isInvalid={errors.password && touched.password}
                  isRequired
                >
                  <FormLabel>Password</FormLabel>
                  <Field as={Input} type="password" name="password" placeholder="Password" />
                  {errors.password && touched.password && (
                    <Text color="red.500" fontSize="sm">
                      {errors.password}
                    </Text>
                  )}
                </FormControl>

                <Button
                  type="submit"
                  colorScheme="blue"
                  width="100%"
                  isLoading={isSubmitting || loading}
                >
                  {isRegister ? 'Register' : 'Login'}
                </Button>
              </Form>
            )}
          </Formik>

          <Button
            variant="link"
            mt={2}
            onClick={() => {
              setIsRegister(!isRegister);
              setShowResendVerification(false);
            }}
          >
            {isRegister
              ? 'Already have an account? Login'
              : 'Need an account? Register'}
          </Button>

          {/* Show "Resend Verification Email" if user not verified */}
          {showResendVerification && (
            <Box mt={3}>
              <Button
                colorScheme="purple"
                variant="outline"
                size="sm"
                onClick={handleResendVerification}
              >
                Resend Verification Email
              </Button>
            </Box>
          )}
        </CardBody>
      </Card>
    </Box>
  );
}

export default LandingPage;
