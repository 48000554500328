// File: client/src/components/Garden/ChatWindow.js

import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Box, Heading, Button, Input, Text, VStack } from '@chakra-ui/react';

function ChatWindow({ projectId, socket }) {
  const user = useSelector((state) => state.auth.user);
  const collabCursors = useSelector((state) => state.collab.cursors);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [chatName, setChatName] = useState('');
  const [nameSet, setNameSet] = useState(false);

  const typingTimeoutRef = useRef(null);

  useEffect(() => {
    if (!projectId || !socket) return;

    const handleChat = (msg) => {
      setMessages((prev) => [...prev, msg]);
    };
    socket.on('chatMessage', handleChat);

    return () => {
      socket.off('chatMessage', handleChat);
    };
  }, [projectId, socket]);

  const handleTypingStatus = () => {
    if (!socket || !projectId || !user) return;
    socket.emit('cursorMove', {
      projectId,
      userId: user.id,
      username: user.username,
      color: user.cursorColor || '#ff00ff',
      x: collabCursors[user.id]?.x || 0,
      y: collabCursors[user.id]?.y || 0,
      tool: collabCursors[user.id]?.tool || 'none',
      chatStatus: 'typing'
    });

    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }
    typingTimeoutRef.current = setTimeout(() => {
      socket.emit('cursorMove', {
        projectId,
        userId: user.id,
        username: user.username,
        color: user.cursorColor || '#ff00ff',
        x: collabCursors[user.id]?.x || 0,
        y: collabCursors[user.id]?.y || 0,
        tool: collabCursors[user.id]?.tool || 'none',
        chatStatus: 'idle'
      });
    }, 2000);
  };

  const handleInputChange = (e) => {
    setInput(e.target.value);
    if (nameSet) {
      handleTypingStatus();
    }
  };

  const handleSend = () => {
    if (!nameSet || !chatName.trim()) {
      return alert('Please enter a name first.');
    }
    if (!input.trim()) return;
    const newMsg = {
      name: chatName,
      text: input.trim(),
      projectId
    };
    socket.emit('chatMessage', newMsg);
    setInput('');
  };

  const handleSetName = () => {
    if (!chatName.trim()) {
      return alert('Enter a valid display name');
    }
    setNameSet(true);
  };

  const collaboratorsList = Object.entries(collabCursors).map(([uid, data]) => (
    <Text key={uid} fontSize="xs">
      <span style={{ color: data.color || '#ff00ff' }}>
        {data.username || 'User'}
      </span>
      {data.tool && data.tool !== 'none' && ` (using: ${data.tool})`}
      {data.chatStatus === 'typing' && ' (typing...)'}
    </Text>
  ));

  return (
    <Box
      position="absolute"
      right="10px"
      bottom="10px"
      width="250px"
      background="white"
      border="1px solid #ccc"
      p={2}
      zIndex={10}
      aria-label="Chat Window"
    >
      <Heading as="h4" size="sm" mb={2}>
        Chat
      </Heading>
      {/* Collaborators present in this project, plus their statuses */}
      <Box mb={2}>{collaboratorsList}</Box>
      {!nameSet && (
        <VStack spacing={2} mb={2}>
          <Text>Enter Display Name:</Text>
          <Input
            value={chatName}
            onChange={(e) => setChatName(e.target.value)}
            size="sm"
          />
          <Button onClick={handleSetName} size="sm" colorScheme="blue">
            Set Name
          </Button>
        </VStack>
      )}

      <Box
        maxH="100px"
        overflowY="auto"
        mb={2}
        border="1px solid #eee"
        p={1}
      >
        {messages.map((m, i) => (
          <Text key={i} fontSize="sm">
            <strong>{m.name}:</strong> {m.text}
          </Text>
        ))}
      </Box>
      <Input
        value={input}
        onChange={handleInputChange}
        size="sm"
        mb={1}
        placeholder="Type a message..."
      />
      <Button onClick={handleSend} size="sm" colorScheme="blue" width="100%">
        Send
      </Button>
    </Box>
  );
}

export default ChatWindow;
