// File: client/src/components/Help/HelpPage.js

import React, { useEffect, useState } from 'react';
import axios from '../../api/axiosInstance.js';
import { Box, Heading, Input, Spinner, Text, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon } from '@chakra-ui/react';

function HelpPage() {
const [topics, setTopics] = useState([]);
const [search, setSearch] = useState('');
const [loading, setLoading] = useState(false);

useEffect(() => {
loadHelp('');
}, []);

const loadHelp = async (query) => {
setLoading(true);

try {
const res = await axios.get('/help', { params: { search: query } });
setTopics(res.data);
} catch (err) {
console.error('Help load error:', err);
} finally {
setLoading(false);
}
};

const handleSearch = (e) => {
setSearch(e.target.value);
loadHelp(e.target.value);
};

return (
<Box p={4}>
<Heading as="h2" mb={4}>Help & Documentation</Heading>
<Input
placeholder="Search help..."
value={search}
onChange={handleSearch}

mb={4}
aria-label="Help Search"
/>
{loading && <Spinner />}
<Accordion allowMultiple>
{topics.map(topic => (
<AccordionItem key={topic.id}>
<AccordionButton>
<Box flex="1" textAlign="left">
{topic.title}
</Box>
<AccordionIcon />
</AccordionButton>
<AccordionPanel pb={4}>
{/* Potential advanced popover tips */}
<div dangerouslySetInnerHTML={{ __html: topic.content }} />
</AccordionPanel>
</AccordionItem>
))}
</Accordion>

{topics.length === 0 && !loading && (

<Text>No help topics found.</Text>
)}
</Box>
);
}

export default HelpPage;