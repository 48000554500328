// File: client/src/components/Auth/ForgotPassword.js
import React, { useState } from 'react';
import axios from '../../api/axiosInstance.js';
import {
  Box,
  Heading,
  Text,
  Button,
  FormControl,
  FormLabel,
  Input
} from '@chakra-ui/react';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post('/auth/forgot-password', { email });
      setMessage(res.data.message || 'If that account exists, an email was sent.');
    } catch (err) {
      setMessage(err.response?.data?.error || 'Error sending reset email.');
    }
  };

  return (
    <Box maxW="400px" mt="3rem" mx="auto" textAlign="center">
      <Heading as="h2" mb={4}>
        Forgot Password
      </Heading>
      {message && <Text mb={4}>{message}</Text>}
      <form onSubmit={handleSubmit}>
        <FormControl mb={3} isRequired>
          <FormLabel>Email</FormLabel>
          <Input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            aria-label="Forgot Password Email Field"
          />
        </FormControl>
        <Button type="submit" colorScheme="blue" width="100%">
          Send Reset Email
        </Button>
      </form>
    </Box>
  );
}

export default ForgotPassword;
