// File: client/src/components/Garden/LocalCursorOverlay.js

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

/**
 * Displays a local circle to represent the paint/eraser brush on top of the canvas.
 * Now uses the bounding rect of .garden-canvas to align properly with the actual pointer.
 */
export default function LocalCursorOverlay() {
  const toolMode = useSelector((state) => state.garden.toolMode);
  const { x, y } = useSelector((state) => state.garden.pointerPosition);
  const { offsetX, offsetY, scale } = useSelector((state) => state.garden.canvasTransform);

  const paintingState = useSelector((state) => state.painting);
  const { color, size, eraserSize } = paintingState;

  // We'll track the .garden-canvas bounding rect to compute absolute positioning
  const [containerRect, setContainerRect] = useState(null);

  useEffect(() => {
    const container = document.querySelector('.garden-canvas');
    if (container) {
      setContainerRect(container.getBoundingClientRect());
    }
  }, []);

  // Show a circle only if the user is painting or erasing
  const isDrawingTool = toolMode === 'paint' || toolMode === 'eraser';
  if (!isDrawingTool) {
    return null;
  }

  if (!containerRect) {
    return null;
  }

  // Determine circle color and diameter
  const circleColor = (toolMode === 'eraser') ? 'red' : color;
  const diameter = (toolMode === 'eraser') ? eraserSize : size;

  // Convert the local "canvas" coords to actual screen coords
  const screenX = containerRect.left + offsetX + x * scale;
  const screenY = containerRect.top + offsetY + y * scale;

  return (
    <div
      style={{
        position: 'absolute',
        left: screenX,
        top: screenY,
        transform: 'translate(-50%, -50%)',
        pointerEvents: 'none',
        width: diameter,
        height: diameter,
        borderRadius: '50%',
        backgroundColor: circleColor,
        opacity: 0.6,
        zIndex: 999999,
        cursor: 'crosshair'
      }}
    />
  );
}
