// File: client/src/components/Projects/WizardOnboarding.js
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createProject } from '../../store/projectsSlice.js';
import { useNavigate } from 'react-router-dom';
import { Box, Heading, FormControl, FormLabel, Input, Button, Text } from '@chakra-ui/react';

function WizardOnboarding() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error } = useSelector((state) => state.projects);

  // If the user wants to allow decimal or fractional dimensions, use parseFloat
  const [formData, setFormData] = useState({
    name: '',
    width: 50,
    height: 50,
    unit: 'feet'
  });

  const [msg, setMsg] = useState('');

  const handleFinish = async () => {
    setMsg('');
    // Convert to float in case user typed decimals
    const payload = {
      name: formData.name,
      width: parseFloat(formData.width),
      height: parseFloat(formData.height),
      unit: formData.unit
    };

    const result = await dispatch(createProject(payload));
    if (result.meta.requestStatus === 'fulfilled') {
      if (result.payload && !result.payload.error) {
        setMsg('Project created successfully!');
        navigate('/dashboard');
      } else if (result.payload && result.payload.error) {
        setMsg(`Error: ${result.payload.error}`);
      }
    } else {
      setMsg(`Creation failed: ${result.payload || error}`);
    }
  };

  return (
    <Box maxW="500px" mx="auto" mt="3rem">
      <Heading as="h2" mb={4}>Onboarding Wizard</Heading>
      {msg && <Text color="red.500" mb={2}>{msg}</Text>}
      {error && <Text color="red.500" mb={2}>{error}</Text>}

      <FormControl mb={3}>
        <FormLabel>Project Name</FormLabel>
        <Input
          value={formData.name}
          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
        />
      </FormControl>

      <FormControl mb={3}>
        <FormLabel>Width (numeric)</FormLabel>
        <Input
          type="number"
          value={formData.width}
          onChange={(e) => setFormData({ ...formData, width: e.target.value })}
        />
      </FormControl>

      <FormControl mb={3}>
        <FormLabel>Height (numeric)</FormLabel>
        <Input
          type="number"
          value={formData.height}
          onChange={(e) => setFormData({ ...formData, height: e.target.value })}
        />
      </FormControl>

      <FormControl mb={3}>
        <FormLabel>Unit</FormLabel>
        <Input
          value={formData.unit}
          onChange={(e) => setFormData({ ...formData, unit: e.target.value })}
        />
      </FormControl>

      <Button colorScheme="blue" onClick={handleFinish} isLoading={loading}>
        Finish
      </Button>
    </Box>
  );
}

export default WizardOnboarding;
