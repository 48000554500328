// File: client/src/components/Garden/TrashPanel.js

import React, { useEffect, useState } from

'react';
import axios from '../../api/axiosInstance.js';
import { Box, Heading, UnorderedList, ListItem, Button, Spinner, Text } from '@chakra-ui/react';

function TrashPanel() {
const [trash, setTrash] = useState([]);
const [loading, setLoading] = useState(false);
const [error, setError] = useState('');

const loadTrash = async () => {
setLoading(true);
setError('');
try {
const res = await axios.get('/projects/trash');
setTrash(res.data);
} catch (err) {
console.error('TrashPanel Error:', err);
setError(err.response?.data?.error || 'Error fetching trash items.');
} finally {
setLoading(false);

}
};

const restoreItem = async (id) => {
try {
const resp = await axios.post(`/projects/trash/${id}/restore`);
if (resp.data.success) {
loadTrash();
} else {
setError(resp.data.error || 'Unknown restore error.');
}
} catch (err) {
console.error('Restore Error:', err);
setError(err.response?.data?.error || 'Error restoring project.');
}
};

useEffect(() => {
loadTrash();

}, []);

return (
<Box p={2}>
<Heading as="h3" size="sm" mb={2}>Trash</Heading>
{loading && <Spinner />}
{error && <Text color="red.500" mt={2} mb={2}>{error}</Text>}

<UnorderedList>
{trash.map((item) => {
const data = item.item_data || {};
const displayName = data.name || 'Deleted Item';
return (
<ListItem key={item.id} mb={2}>
<strong>{displayName}</strong>
<Button
ml={2}
size="sm"
onClick={() => restoreItem(item.id)}
colorScheme="blue"

>
Restore
</Button>
</ListItem>
);
})}
</UnorderedList>
</Box>
);
}

export default TrashPanel;
