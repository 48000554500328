// File: client/src/hooks/useAutoSave.js

import { useEffect, useRef } from 'react';
import axios from '../api/axiosInstance.js';
import { useDispatch, useSelector } from 'react-redux';
import { loadDesignData } from '../store/gardenSlice.js';
import { toast } from 'react-hot-toast';

function useAutoSave(project, objects, shapes) {
  const dispatch = useDispatch();
  const saveTimeout = useRef(null);

  // Pull in layers & canvasTransform so we store them too
  const { layers, canvasTransform } = useSelector((state) => state.garden);

  useEffect(() => {
    if (!project) return;
    if (!project.auto_save) return;

    if (saveTimeout.current) {
      clearTimeout(saveTimeout.current);
    }

    saveTimeout.current = setTimeout(async () => {
      try {
        // Now we include layers & canvasTransform so undo/redo captures everything
        const data = { objects, shapes, layers, canvasTransform };
        const res = await axios.put(`/projects/${project.id}`, {
          name: project.name,
          width: project.width,
          height: project.height,
          unit: project.unit,
          data,
          auto_save: true
        });
        dispatch(loadDesignData({ ...res.data.data, ...res.data }));
      } catch (err) {
        console.error('Auto-save error:', err);
        toast.error('Auto-save failed. See console for details.');
      }
    }, 1500);

    return () => {
      if (saveTimeout.current) {
        clearTimeout(saveTimeout.current);
      }
    };
  }, [project, objects, shapes, layers, canvasTransform, dispatch]);

  return null;
}

export default useAutoSave;
