// File: client/src/components/Community/CommunityPage.js

import React, { useEffect, useState } from 'react';
import axios from '../../api/axiosInstance.js';
import {
  Box,
  Heading,
  Spinner,
  UnorderedList,
  ListItem,
  Button,
  Text,
  Input,
  VStack
} from '@chakra-ui/react';

function CommunityPage() {
  const [gallery, setGallery] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [comments, setComments] = useState([]);
  const [commentLoading, setCommentLoading] = useState(false);
  const [newComment, setNewComment] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  const loadGallery = async () => {
    setLoading(true);
    try {
      const res = await axios.get('/community');
      setGallery(res.data);
    } catch (err) {
      console.error('CommunityPage Error:', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadGallery();
  }, []);

  const handleLike = async (id) => {
    try {
      await axios.post(`/community/${id}/like`);
      loadGallery();
    } catch (err) {
      console.error(err);
    }
  };

  const handleFork = async (id) => {
    try {
      await axios.post(`/community/${id}/fork`);
      alert('Design forked successfully.');
      loadGallery();
    } catch (err) {
      console.error(err);
    }
  };

  const loadComments = async (id) => {
    if (id === selectedItem) {
      setSelectedItem(null);
      setComments([]);
      return;
    }
    setSelectedItem(id);
    setCommentLoading(true);
    setComments([]);
    try {
      const res = await axios.get(`/community/${id}/comments`);
      setComments(res.data);
    } catch (err) {
      console.error('Load comments error:', err);
    } finally {
      setCommentLoading(false);
    }
  };

  const submitComment = async () => {
    if (!selectedItem || !newComment.trim()) return;
    try {
      await axios.post(`/community/${selectedItem}/comments`, {
        comment: newComment.trim()
      });
      setNewComment('');
      loadComments(selectedItem);
    } catch (err) {
      console.error('Submit comment error:', err);
    }
  };

  const filteredGallery = gallery.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Box p={4}>
      <Heading as="h2" mb={4}>
        Community Inspiration
      </Heading>
      {loading && <Spinner />}

      {/* Search input */}
      <Box mb={4}>
        <Input
          placeholder="Search community designs..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          size="sm"
          width="250px"
        />
      </Box>

      <UnorderedList>
        {filteredGallery.map((g) => (
          <ListItem key={g.id} mb={3}>
            <Heading as="h4" size="sm">
              {g.title}
            </Heading>
            <Text>{g.description}</Text>
            <Text>Likes: {g.likes}</Text>
            <Button
              onClick={() => handleLike(g.id)}
              size="sm"
              colorScheme="pink"
              mr={2}
            >
              Like
            </Button>
            <Button
              onClick={() => handleFork(g.id)}
              size="sm"
              colorScheme="blue"
              mr={2}
            >
              Fork
            </Button>
            <Button
              onClick={() => loadComments(g.id)}
              size="sm"
              colorScheme="green"
            >
              {selectedItem === g.id ? 'Hide Comments' : 'Show Comments'}
            </Button>
            {selectedItem === g.id && (
              <Box mt={2} ml={4} p={2} border="1px solid #ccc">
                {commentLoading ? (
                  <Spinner />
                ) : (
                  <>
                    <Heading as="h5" size="xs" mb={2}>
                      Comments
                    </Heading>
                    {comments.length === 0 && <Text>No comments yet.</Text>}
                    <UnorderedList>
                      {comments.map((c) => (
                        <ListItem key={c.id} mb={1}>
                          <strong>{c.username}: </strong>
                          {c.comment}
                        </ListItem>
                      ))}
                    </UnorderedList>
                    <VStack mt={2} spacing={2}>
                      <Input
                        placeholder="Add a comment..."
                        value={newComment}
                        onChange={(e) => setNewComment(e.target.value)}
                        size="sm"
                      />
                      <Button size="sm" colorScheme="teal" onClick={submitComment}>
                        Submit
                      </Button>
                    </VStack>
                  </>
                )}
              </Box>
            )}
          </ListItem>
        ))}
      </UnorderedList>
    </Box>
  );
}

export default CommunityPage;
