// File: client/src/store/calendarSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../api/axiosInstance.js';

export const fetchCalendarEvents = createAsyncThunk('calendar/fetch', async (_, { rejectWithValue }) => {
try {
const res = await axios.get('/calendar');
return res.data;
} catch (err) {
return rejectWithValue(err.response?.data?.error || 'Failed to fetch calendar events');
}
});

const calendarSlice = createSlice({

name: 'calendar',
initialState: {
events: [],
loading: false,
error: null
},
reducers: {},
extraReducers: (builder) => {
builder
.addCase(fetchCalendarEvents.pending, (state) => { state.loading = true; state.error = null; })
.addCase(fetchCalendarEvents.fulfilled, (state, action) => {
state.loading = false;
state.events = action.payload;
})
.addCase(fetchCalendarEvents.rejected, (state, action) => {
state.loading = false;
state.error = action.payload;
});
}
});

export default calendarSlice.reducer;