// File: client/src/App.js
import React, { useEffect, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import LandingPage from './components/Auth/LandingPage.js';
import ForgotPassword from './components/Auth/ForgotPassword.js';
import ResetPassword from './components/Auth/ResetPassword.js';
import GardenPage from './components/Garden/GardenPage.js';
import CommunityPage from './components/Community/CommunityPage.js';
import HelpPage from './components/Help/HelpPage.js';
import UserProfilePage from './components/UserProfile/UserProfilePage.js';
import ProjectManager from './components/Projects/ProjectManager.js';
import WizardOnboarding from './components/Projects/WizardOnboarding.js';
import ProtectedRoute from './components/ProtectedRoute.js';
import EventsCalendar from './components/Calendar/EventsCalendar.js';
import NavBar from './components/NavBar.js';
import { initializeAuth } from './store/authSlice.js';

const AdminDashboard = React.lazy(() => import('./components/Admin/AdminDashboard.js'));

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorMsg: '' };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, errorMsg: error.toString() };
  }

  componentDidCatch(error, info) {
    console.error('ErrorBoundary caught error:', error, info);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={{ textAlign: 'center', marginTop: '3rem', color: 'red' }}>
          <h2>Something went wrong.</h2>
          <p>{this.state.errorMsg}</p>
        </div>
      );
    }
    return this.props.children;
  }
}

export default function App() {
  const dispatch = useDispatch();
  const { initialized } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(initializeAuth());
  }, [dispatch]);

  if (!initialized) {
    return (
      <div style={{ textAlign: 'center', marginTop: '3rem' }}>
        Checking session...
      </div>
    );
  }

  return (
    <Router>
      <NavBar />
      <AnimatePresence mode="wait">
        <ErrorBoundary>
          <Suspense
            fallback={
              <div style={{ textAlign: 'center', marginTop: '3rem' }}>
                Loading...
              </div>
            }
          >
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/login" element={<LandingPage />} />
              <Route path="/register" element={<LandingPage />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/reset-password" element={<ResetPassword />} />

              <Route
                path="/onboarding"
                element={
                  <ProtectedRoute>
                    <WizardOnboarding />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/dashboard"
                element={
                  <ProtectedRoute>
                    <ProjectManager />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/garden"
                element={
                  <ProtectedRoute>
                    <GardenPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/community"
                element={
                  <ProtectedRoute>
                    <CommunityPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/help"
                element={
                  <ProtectedRoute>
                    <HelpPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/calendar"
                element={
                  <ProtectedRoute>
                    <EventsCalendar />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/profile"
                element={
                  <ProtectedRoute>
                    <UserProfilePage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/admin"
                element={
                  <ProtectedRoute adminOnly>
                    <AdminDashboard />
                  </ProtectedRoute>
                }
              />
            </Routes>
          </Suspense>
        </ErrorBoundary>
      </AnimatePresence>
    </Router>
  );
}
