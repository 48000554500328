// File: client/src/components/Garden/SettingsPanel.js

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Switch,
  Select,
  Collapse,
  useColorModeValue,
  useColorMode
} from '@chakra-ui/react';
import {
  toggleDarkMode,
  setEraserMode
} from '../../store/userSettingsSlice.js';

export default function SettingsPanel() {
  const dispatch = useDispatch();
  const { darkMode, eraserMode } = useSelector((state) => state.userSettings);

  const [isOpen, setIsOpen] = useState(false);

  const bg = useColorModeValue('gray.100', 'gray.700');

  // [FIX] We integrate Chakra’s colorMode
  const { colorMode, toggleColorMode } = useColorMode();

  // Whenever our Redux “darkMode” differs from the Chakra colorMode, sync them.
  useEffect(() => {
    if (darkMode && colorMode === 'light') {
      toggleColorMode();
    } else if (!darkMode && colorMode === 'dark') {
      toggleColorMode();
    }
  }, [darkMode, colorMode, toggleColorMode]);

  const handleToggle = () => setIsOpen(!isOpen);

  const handleDarkModeToggle = () => {
    // Flip in Redux store
    dispatch(toggleDarkMode());
  };

  const handleEraserModeChange = (e) => {
    dispatch(setEraserMode(e.target.value));
  };

  return (
    <Box
      position="relative"
      width="100%"
      bg={bg}
      p={2}
      borderBottom="1px solid"
      borderColor={useColorModeValue('gray.200', 'gray.600')}
    >
      {/* A button to toggle collapse */}
      <Button size="sm" onClick={handleToggle} variant="outline" mr={3}>
        {isOpen ? 'Hide Settings' : 'Show Settings'}
      </Button>

      <Collapse in={isOpen} animateOpacity>
        <Box mt={2} p={3} bg={useColorModeValue('white', 'gray.800')} borderRadius="md">
          <FormControl display="flex" alignItems="center" mb={3}>
            <FormLabel mb="0" mr={2} fontSize="sm">
              Dark Mode
            </FormLabel>
            <Switch
              isChecked={darkMode}
              onChange={handleDarkModeToggle}
              size="sm"
              colorScheme="teal"
            />
          </FormControl>

          <FormControl mb={3}>
            <FormLabel fontSize="sm">Eraser Mode</FormLabel>
            <Select
              size="sm"
              value={eraserMode}
              onChange={handleEraserModeChange}
            >
              <option value="wholeStroke">Whole Stroke</option>
              <option value="partial">Partial</option>
            </Select>
          </FormControl>
        </Box>
      </Collapse>
    </Box>
  );
}
