// File: client/src/components/Garden/SeasonsPanel.js

import React, { useEffect, useState } from

'react';
import { Box, Heading, Spinner, Select, Button, Text } from '@chakra-ui/react';
import axios from '../../api/axiosInstance.js';
import { useSelector } from 'react-redux';

function SeasonsPanel() {
const currentProject = useSelector((state) => state.projects.currentProject);
const [loading, setLoading] = useState(false);
const [error, setError] = useState('');
const [seasonEntries, setSeasonEntries] = useState([]);
const [selectedSeason, setSelectedSeason] = useState('spring');
const [message, setMessage] = useState('');

const loadSeasons = async () => {
if (!currentProject) return;
setLoading(true);
setError('');
try {
const res = await axios.get(`/seasonal/${currentProject.id}`);

setSeasonEntries(res.data);
} catch (err) {
console.error('SeasonsPanel load error:', err);
setError(err.response?.data?.error || 'Error loading seasonal data.');
} finally {
setLoading(false);
}
};

useEffect(() => {
loadSeasons();
}, [currentProject]);

const saveSeason = async () => {
if (!currentProject) return;
setMessage('');
setLoading(true);
try {
const dummyData = {
shapes: [],
objects: []
};

await axios.post('/seasonal', {
projectId: currentProject.id,
season: selectedSeason,
data: dummyData
});
setMessage(`Season "${selectedSeason}" saved successfully.`);
loadSeasons();
} catch (err) {
console.error('SaveSeason error:', err);
setError(err.response?.data?.error || 'Error saving season.');
} finally {
setLoading(false);
}
};

const deleteSeason = async (id) => {
setMessage('');
try {
await axios.delete(`/seasonal/${id}`);
setMessage('Season entry deleted.');
loadSeasons();
} catch (err) {

console.error('Delete season error:', err);
setError(err.response?.data?.error || 'Error deleting season.');
}
};

return (
<Box p={3} borderTop="1px solid #ccc">
<Heading as="h3" size="sm" mb={2}>Seasons</Heading>
{loading && <Spinner />}
{error && <Text color="red.500" mb={2}>{error}</Text>}
{message && <Text color="green.600" mb={2}>{message}</Text>}

<Box display="flex" alignItems="center" mb={2}>
<Select
value={selectedSeason}
onChange={(e) => setSelectedSeason(e.target.value)}
width="120px"

mr={2}
>
<option value="spring">Spring</option>
<option value="summer">Summer</option>
<option value="fall">Fall</option>
<option value="winter">Winter</option>
</Select>
<Button colorScheme="blue" size="sm" onClick={saveSeason}>
Save/Update
</Button>
</Box>

<Box mt={4}>
<Heading as="h4" size="xs" mb={2}>Existing Seasons</Heading>
{seasonEntries.map((entry) => (
<Box
key={entry.id}
p={2}
mb={2}
border="1px solid #ddd"
borderRadius="md"
>

<Text><strong>Season:</strong> {entry.season}</Text>
<Button
size="xs"
colorScheme="red"
mt={2}
onClick={() => deleteSeason(entry.id)}
>
Delete
</Button>
</Box>
))}
</Box>
</Box>
);
}

export default SeasonsPanel;
