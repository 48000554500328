// File: client/src/components/Garden/LayerManager.js

import React, { useState } from 'react';
import { Box, Heading, Button, Input, Flex } from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import { setLayerVisibility, renameLayer, reorderLayer } from '../../store/gardenSlice.js';

function LayerManager() {
const dispatch = useDispatch();
const layers = useSelector((state) => state.garden.layers).slice().sort((a, b) => a.orderIndex - b.orderIndex);

const [renameMap, setRenameMap] = useState({}); // { layerId: "newName" }

const handleToggle = (layerId) => {
const layer = layers.find((l) => l.id === layerId);
if (!layer) return;
dispatch(setLayerVisibility({ layerId, visible: !layer.visible }));
};

const handleRenameChange = (layerId, newName) => {
setRenameMap((prev) => ({ ...prev, [layerId]: newName }));
};

const handleRenameApply = (layerId) => {
if (renameMap[layerId]) {
dispatch(renameLayer({ layerId, newName: renameMap[layerId] }));
}
};

const handleReorder = (layerId, direction) => {
dispatch(reorderLayer({ layerId, direction }));
};

return (
<Box p={2}>
<Heading as="h3" size="sm" mb={2}>Layers</Heading>
{layers.map((layer) => (
<Flex
key={layer.id}

alignItems="center"
mb={2}
bg="gray.50"
p={2}
borderRadius="md"
>
<Button
size="xs"
mr={1}
variant="outline"
onClick={() => handleToggle(layer.id)}
>
{layer.visible ? 'Hide' : 'Show'}
</Button>
<Button
size="xs"
mr={1}
variant="outline"
onClick={() => handleReorder(layer.id, 'up')}
>
↑
</Button>
<Button

size="xs"
mr={1}
variant="outline"
onClick={() => handleReorder(layer.id, 'down')}
>
↓
</Button>
<Input
size="xs"
value={renameMap[layer.id] ?? layer.name}
onChange={(e) => handleRenameChange(layer.id, e.target.value)}
width="120px"
mr={1}
/>
<Button
size="xs"
colorScheme="blue"
onClick={() => handleRenameApply(layer.id)}
>
Rename
</Button>
</Flex>

))}
</Box>
);
}

export default LayerManager;
