// File: client/src/components/Auth/ResetPassword.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../../api/axiosInstance.js';
import {
  Box,
  Heading,
  Text,
  Button,
  FormControl,
  FormLabel,
  Input
} from '@chakra-ui/react';

function ResetPassword() {
  const navigate = useNavigate();
  const [token, setToken] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmNewPassword) {
      setMessage('Passwords do not match.');
      return;
    }
    // Enforce password length >= 8
    if (newPassword.length < 8) {
      setMessage('New password must be at least 8 characters long.');
      return;
    }
    try {
      const res = await axios.post('/auth/reset-password', {
        token,
        newPassword,
        confirmNewPassword
      });
      setMessage(res.data.message || 'Password reset attempt finished.');
      if (res.data.success) {
        navigate('/');
      }
    } catch (err) {
      setMessage(err.response?.data?.error || 'Error resetting password.');
    }
  };

  return (
    <Box maxW="400px" mt="3rem" mx="auto" textAlign="center">
      <Heading as="h2" mb={4}>
        Reset Password
      </Heading>
      {message && <Text mb={4} color="red.500">{message}</Text>}
      <form onSubmit={handleSubmit}>
        <FormControl mb={3} isRequired>
          <FormLabel>Reset Token</FormLabel>
          <Input
            value={token}
            onChange={(e) => setToken(e.target.value)}
            aria-label="Reset Token Field"
          />
        </FormControl>
        <FormControl mb={3} isRequired>
          <FormLabel>New Password</FormLabel>
          <Input
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            aria-label="New Password Field"
          />
        </FormControl>
        <FormControl mb={3} isRequired>
          <FormLabel>Confirm New Password</FormLabel>
          <Input
            type="password"
            value={confirmNewPassword}
            onChange={(e) => setConfirmNewPassword(e.target.value)}
            aria-label="Confirm New Password Field"
          />
        </FormControl>
        <Button type="submit" colorScheme="blue" width="100%">
          Reset Password
        </Button>
      </form>
    </Box>
  );
}

export default ResetPassword;
