// File: client/src/components/NavBar.js
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../store/authSlice.js';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import {
  Flex,
  Button,
  Text,
  Spacer,
  HStack,
  Link as ChakraLink,
  Box,
  useColorMode
} from '@chakra-ui/react';

export default function NavBar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { user, token } = useSelector((state) => state.auth);
  const { colorMode } = useColorMode();

  // Show x,y,scale if on the garden page
  const { x, y } = useSelector((state) => state.garden.pointerPosition);
  const { scale } = useSelector((state) => state.garden.canvasTransform);

  const handleLogout = async () => {
    try {
      const result = await dispatch(logout());
      if (result.meta.requestStatus === 'fulfilled') {
        navigate('/');
      }
    } catch (err) {
      console.error('Logout error:', err);
    }
  };

  return (
    <Flex
      as="nav"
      bgColor={colorMode === 'dark' ? 'gray.700' : 'gray.100'}
      p={4}
      alignItems="center"
      aria-label="Main Navigation"
      boxShadow="sm"
      flexWrap="wrap"
    >
      <Box>
        <ChakraLink
          as={RouterLink}
          to="/"
          fontWeight="bold"
          textDecoration="none"
          _hover={{ textDecoration: 'none' }}
          fontSize="lg"
          mr={6}
        >
          Garden Planner
        </ChakraLink>
      </Box>
      <HStack spacing={4}>
        {user && token && (
          <>
            <ChakraLink
              as={RouterLink}
              to="/dashboard"
              fontWeight="medium"
              _hover={{ textDecoration: 'underline' }}
            >
              Dashboard
            </ChakraLink>
            <ChakraLink
              as={RouterLink}
              to="/community"
              fontWeight="medium"
              _hover={{ textDecoration: 'underline' }}
            >
              Community
            </ChakraLink>
          </>
        )}
      </HStack>
      <Spacer />

      {/* If we're on the garden page, show coords & scale */}
      {location.pathname === '/garden' && (
        <Box mr={4} fontSize="sm" color="teal.600">
          x={Math.round(x)}, y={Math.round(y)}, scale={scale.toFixed(2)}x
        </Box>
      )}

      <Box>
        {user && token ? (
          <>
            <Text as="span" mr={4} fontWeight="medium">
              Hello, {user.username}!
            </Text>
            <Button colorScheme="red" size="sm" onClick={handleLogout} mr={2}>
              Logout
            </Button>
          </>
        ) : (
          <>
            {location.pathname !== '/login' && (
              <Button as={RouterLink} to="/login" colorScheme="blue" size="sm" mr={2}>
                Login
              </Button>
            )}
            {location.pathname !== '/register' && (
              <Button as={RouterLink} to="/register" colorScheme="green" size="sm">
                Register
              </Button>
            )}
          </>
        )}
      </Box>
    </Flex>
  );
}
