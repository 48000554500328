// File: client/src/components/Projects/ProjectManager.js

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProjects, setCurrentProject } from '../../store/projectsSlice.js';
import { useNavigate } from 'react-router-dom';
import axios from '../../api/axiosInstance.js';
import {
  Box,
  Heading,
  Spinner,
  UnorderedList,
  ListItem,
  Button,
  Text,
  useToast,
  useColorModeValue
} from '@chakra-ui/react';

function ProjectManager() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();

  const { list, loading, error } = useSelector((state) => state.projects);

  // For dark mode styling
  const bg = useColorModeValue('gray.50', 'gray.900');
  const textColor = useColorModeValue('gray.800', 'gray.100');

  useEffect(() => {
    dispatch(fetchProjects());
  }, [dispatch]);

  const handleSelectProject = (proj) => {
    dispatch(setCurrentProject(proj));
    navigate('/garden');
  };

  const handleNewProject = () => {
    navigate('/onboarding');
  };

  const handleDeleteProject = async (projectId) => {
    if (!window.confirm('Are you sure you want to delete this project?')) {
      return;
    }
    try {
      const res = await axios.delete(`/projects/${projectId}`);
      if (res.data && res.data.success) {
        toast({ title: 'Project deleted', status: 'success' });
        dispatch(fetchProjects());
      } else {
        toast({
          title: 'Error deleting project',
          description: res.data.error || 'Could not delete project.',
          status: 'error'
        });
      }
    } catch (err) {
      console.error(err);
      toast({
        title: 'Request failed',
        description: err.response?.data?.error || err.message,
        status: 'error'
      });
    }
  };

  return (
    <Box p={4} bg={bg} minH="100vh">
      <Heading as="h2" mb={4} color={textColor}>
        Project Dashboard
      </Heading>
      {loading && <Spinner />}
      {error && <Text color="red.500" mb={2}>{error}</Text>}

      <Button colorScheme="blue" mb={4} onClick={handleNewProject}>
        Start a New Project
      </Button>

      {list.length === 0 && !loading && !error && (
        <Text color={textColor}>No projects found. Start your first project!</Text>
      )}
      <UnorderedList>
        {list.map((proj) => (
          <ListItem key={proj.id} mb={2}>
            <Text as="span" color={textColor} fontWeight="semibold">
              {proj.name}
            </Text>{' '}
            (Updated: {new Date(proj.updated_at).toLocaleDateString()})
            <Button
              ml={3}
              size="sm"
              colorScheme="teal"
              onClick={() => handleSelectProject(proj)}
            >
              Continue
            </Button>
            <Button
              ml={3}
              size="sm"
              colorScheme="red"
              variant="outline"
              onClick={() => handleDeleteProject(proj.id)}
            >
              Delete
            </Button>
          </ListItem>
        ))}
      </UnorderedList>
    </Box>
  );
}

export default ProjectManager;
