// File: client/src/components/Garden/Sidebar.js

import React, { useEffect, useState } from 'react';
import axios from '../../api/axiosInstance.js';
import { Tabs, TabList, TabPanels, Tab, TabPanel, Input, Box } from '@chakra-ui/react';
import VirtualizedList from './VirtualizedList.js';

function Sidebar() {
const [searchTerm, setSearchTerm] = useState('');

const [plants, setPlants] = useState([]);
const [vegetables, setVegetables] = useState([]);
const [trees, setTrees] = useState([]);
const [structures, setStructures] = useState([]);

const [loadedTabs, setLoadedTabs] = useState({
plants: false,
vegetables: false,
trees: false,
structures: false
});
const [loading, setLoading] = useState(false);

const handleTabChange = async (index) => {
switch (index) {
case 0:
if (!loadedTabs.plants) await loadPlants();
break;
case 1:
if (!loadedTabs.vegetables) await loadVegetables();

break;
case 2:
if (!loadedTabs.trees) await loadTrees();
break;
case 3:
if (!loadedTabs.structures) await loadStructures();
break;
default:
break;
}
};

const loadPlants = async () => {
setLoading(true);
try {
const res = await axios.get('/plants');
setPlants(res.data);
setLoadedTabs((prev) => ({ ...prev, plants: true }));
} catch (err) {
console.error('Sidebar Load Plants Error:', err);
} finally {

setLoading(false);
}
};
const loadVegetables = async () => {
setLoading(true);
try {
const res = await axios.get('/vegetables');
setVegetables(res.data);
setLoadedTabs((prev) => ({ ...prev, vegetables: true }));
} catch (err) {
console.error('Sidebar Load Veg Error:', err);
} finally {
setLoading(false);
}
};
const loadTrees = async () => {
setLoading(true);
try {
const res = await axios.get('/trees');
setTrees(res.data);
setLoadedTabs((prev) => ({ ...prev, trees: true }));
} catch (err) {

console.error('Sidebar Load Trees Error:', err);
} finally {
setLoading(false);
}
};
const loadStructures = async () => {
setLoading(true);
try {
const res = await axios.get('/structures');
setStructures(res.data);
setLoadedTabs((prev) => ({ ...prev, structures: true }));
} catch (err) {
console.error('Sidebar Load Structures Error:', err);
} finally {
setLoading(false);
}
};

const filterItems = (items) => {
if (!searchTerm.trim()) return items;
return items.filter((it) =>

it.name.toLowerCase().includes(searchTerm.toLowerCase())
);
};

return (
<Box w="100%" h="100%">
<Box p={2}>
<Input
placeholder="Search..."
size="sm"
value={searchTerm}
onChange={(e) => setSearchTerm(e.target.value)}
/>
</Box>
<Tabs
orientation="vertical"
w="100%"
overflowY="auto"
borderRight="1px solid #ccc"
onChange={handleTabChange}
variant="enclosed"
>

<TabList>
<Tab>Plants</Tab>
<Tab>Vegetables</Tab>
<Tab>Trees</Tab>
<Tab>Structures</Tab>
</TabList>
<TabPanels>
<TabPanel>
<VirtualizedList
items={filterItems(plants)}
itemType="plant"
loading={loading && !loadedTabs.plants}
/>
</TabPanel>
<TabPanel>
<VirtualizedList
items={filterItems(vegetables)}
itemType="vegetable"
loading={loading && !loadedTabs.vegetables}
/>
</TabPanel>
<TabPanel>
<VirtualizedList

items={filterItems(trees)}
itemType="tree"
loading={loading && !loadedTabs.trees}
/>
</TabPanel>
<TabPanel>
<VirtualizedList
items={filterItems(structures)}
itemType="structure"
loading={loading && !loadedTabs.structures}
/>
</TabPanel>
</TabPanels>
</Tabs>
</Box>
);
}

export default Sidebar;
