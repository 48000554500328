// File: client/src/components/Garden/ContainerLogic.js

import React from 'react';

export function canStackContainers(containerA, containerB) {
  const overlap = doTheyOverlap(containerA, containerB);
  return overlap;
}

function doTheyOverlap(a, b) {
  const ax1 = a.x,
    ay1 = a.y,
    ax2 = a.x + a.width,
    ay2 = a.y + a.height;
  const bx1 = b.x,
    by1 = b.y,
    bx2 = b.x + b.width,
    by2 = b.y + b.height;

  return ax1 < bx2 && ax2 > bx1 && ay1 < by2 && ay2 > by1;
}

/**
 * Future stub for stacking containers. For now, it is not implemented.
 * This can be expanded in future to adjust 'topContainer' position, etc.
 */
export function stackContainerOnTop(baseContainer, topContainer) {
  // STUB: Not yet implemented
  // Possibly set topContainer.y = baseContainer.y + baseContainer.height
}
