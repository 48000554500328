// File: client/src/components/Garden/ImportModal.js

import React, { useState } from 'react';
import {
Modal,
ModalOverlay,
ModalContent,
ModalHeader,
ModalBody,
ModalFooter,
Button,
Textarea,
Radio,
RadioGroup,
Stack,
useToast
} from '@chakra-ui/react';
import axios from '../../api/axiosInstance.js';
import { useSelector, useDispatch } from 'react-redux';
import { loadDesignData } from '../../store/gardenSlice.js';

import { setCurrentProject, fetchProjects } from '../../store/projectsSlice.js';

export default function ImportModal({ isOpen, onClose }) {
const dispatch = useDispatch();
const toast = useToast();
const currentProject = useSelector((state) => state.projects.currentProject);

const [importOption, setImportOption] = useState('overwrite');
const [jsonInput, setJsonInput] = useState('');

const handleImport = async () => {
if (!jsonInput.trim()) {
toast({
title: 'No data to import',
status: 'error',
isClosable: true
});
return;
}

let parsed;
try {
parsed = JSON.parse(jsonInput);
} catch (err) {
toast({
title: 'Invalid JSON',
description: err.message,
status: 'error',
isClosable: true
});
return;
}

if (importOption === 'overwrite') {
if (!currentProject) {
toast({
title: 'No current project',
description: 'Cannot overwrite without an open project.',
status: 'error',
isClosable: true
});
return;

}
try {
const updated = await axios.put(`/projects/${currentProject.id}`, {
name: currentProject.name,
width: parsed.width || currentProject.width,
height: parsed.height || currentProject.height,
unit: parsed.unit || currentProject.unit,
data: {
objects: parsed.objects || [],
shapes: parsed.shapes || [],
layers: parsed.layers || [],
canvasTransform: parsed.canvasTransform || {}
},
auto_save: false,
is_public: currentProject.is_public
});
dispatch(loadDesignData(updated.data.data));
dispatch(setCurrentProject(updated.data));
toast({
title: 'Import successful',
status: 'success',

isClosable: true
});
onClose();
} catch (err) {
console.error(err);
toast({
title: 'Error overwriting project',
description: err.response?.data?.error || 'Unknown error',
status: 'error',
isClosable: true
});
}
} else {
try {
const res = await axios.post('/projects', {
name: parsed.name || `Imported Project ${Date.now()}`,
width: parsed.width || 50,
height: parsed.height || 50,
unit: parsed.unit || 'feet',
is_public: false
});

const newProject = res.data;

await axios.post(`/projects/${newProject.id}/manual-save`, {
data: {
objects: parsed.objects || [],
shapes: parsed.shapes || [],
layers: parsed.layers || [],
canvasTransform: parsed.canvasTransform || {}
}
});
dispatch(fetchProjects());
toast({
title: 'New project imported',
description: 'Check your dashboard to open it.',
status: 'success',
isClosable: true
});
onClose();
} catch (err) {
console.error(err);
toast({

title: 'Error creating new project',
description: err.response?.data?.error || 'Unknown error',
status: 'error',
isClosable: true
});
}
}
};

return (
<Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
<ModalOverlay />
<ModalContent>
<ModalHeader>Import Design</ModalHeader>
<ModalBody>
<RadioGroup onChange={setImportOption} value={importOption} mb={4}>
<Stack direction="row" spacing={6}>
<Radio value="overwrite">Overwrite Current Project</Radio>

<Radio value="new">Create New Project</Radio>
</Stack>
</RadioGroup>
<Textarea
placeholder="Paste the JSON you want to import here"
value={jsonInput}
onChange={(e) => setJsonInput(e.target.value)}
rows={10}
/>
</ModalBody>
<ModalFooter>
<Button onClick={handleImport} colorScheme="blue" mr={3}>
Import
</Button>
<Button variant="ghost" onClick={onClose}>Cancel</Button>
</ModalFooter>
</ModalContent>
</Modal>
);

}
