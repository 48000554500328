// File: client/src/components/Garden/ItemListPanel.js
import React from 'react';
import { Box, Heading } from '@chakra-ui/react';

function ItemListPanel() {
return (
<Box p={2}>
<Heading as="h3" size="sm" mb={2}>Items</Heading>
{/* Show list of items on canvas, reorder, etc. */}
</Box>
);
}

export default ItemListPanel;