// File: client/src/store/paintingSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  brushType: 'round',
  color: '#ff0000',
  size: 10,
  opacity: 1.0,
  gradient: false,
  pattern: '',
  // New field for separate eraser size:
  eraserSize: 10,

  partialStrokes: [],
  partialStrokesByUser: {}
};

const paintingSlice = createSlice({
  name: 'painting',
  initialState,
  reducers: {
    setBrushType(state, action) {
      state.brushType = action.payload;
    },
    setColor(state, action) {
      state.color = action.payload;
    },
    setSize(state, action) {
      state.size = action.payload;
    },
    setOpacity(state, action) {
      state.opacity = action.payload;
    },
    setGradient(state, action) {
      state.gradient = action.payload;
    },
    setPattern(state, action) {
      state.pattern = action.payload;
    },
    // New setter for eraserSize:
    setEraserSize(state, action) {
      state.eraserSize = action.payload;
    },

    addPartialStroke(state, action) {
      state.partialStrokes.push(action.payload);
    },
    finalizeStroke(state) {
      state.partialStrokes = [];
    },
    addRemotePartialStroke(state, action) {
      const {
        userId,
        type,
        brushType,
        brushColor,
        size,
        opacity,
        gradient,
        pattern,
        segment,
        start,
        end,
        updatedAt
      } = action.payload;

      if (!state.partialStrokesByUser[userId]) {
        state.partialStrokesByUser[userId] = {
          type,
          brushType,
          brushColor,
          size,
          opacity,
          gradient,
          pattern,
          points: [],
          updatedAt: Date.now()
        };
      }
      const remote = state.partialStrokesByUser[userId];
      remote.type = type;
      remote.updatedAt = updatedAt || Date.now();

      if (type === 'paint') {
        remote.brushType = brushType;
        remote.brushColor = brushColor;
        remote.size = size;
        remote.opacity = opacity;
        remote.gradient = gradient;
        remote.pattern = pattern;
        if (!Array.isArray(remote.points)) {
          remote.points = [];
        }
        if (segment) {
          remote.points.push(...segment);
        }
      } else if (type === 'measure') {
        remote.color = brushColor || '#000';
        remote.size = size || 2;
        remote.start = start || null;
        remote.end = end || null;
      }
    },
    finalizeRemoteStroke(state, action) {
      const { userId } = action.payload;
      if (state.partialStrokesByUser[userId]) {
        delete state.partialStrokesByUser[userId];
      }
    },
    resetPainting(state) {
      state.partialStrokes = [];
      state.partialStrokesByUser = {};
    },
    cleanupRemotePartials(state, action) {
      const { maxAge } = action.payload;
      const now = Date.now();
      Object.keys(state.partialStrokesByUser).forEach((uid) => {
        const partial = state.partialStrokesByUser[uid];
        if (now - partial.updatedAt > maxAge) {
          delete state.partialStrokesByUser[uid];
        }
      });
    }
  }
});

export const {
  setBrushType,
  setColor,
  setSize,
  setOpacity,
  setGradient,
  setPattern,
  setEraserSize,
  addPartialStroke,
  finalizeStroke,
  addRemotePartialStroke,
  finalizeRemoteStroke,
  resetPainting,
  cleanupRemotePartials
} = paintingSlice.actions;

export default paintingSlice.reducer;

