// File: client/src/components/Garden/PaintingTools.js

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setBrushType,
  setColor,
  setSize,
  setOpacity,
  setGradient,
  setPattern,
  setEraserSize
} from '../../store/paintingSlice.js';
import { Box, FormLabel, Input, Select, Slider, SliderTrack, SliderFilledTrack, SliderThumb, Switch } from '@chakra-ui/react';

/**
 * PaintingTools: UI for brush/paint/gradient settings, plus an eraserSize slider
 * when the current tool is 'eraser'. We no longer take a prop "currentToolMode";
 * instead we read `toolMode` from Redux.
 */
export default function PaintingTools() {
  const dispatch = useDispatch();
  const painting = useSelector((state) => state.painting);
  const toolMode = useSelector((state) => state.garden.toolMode);

  const {
    brushType,
    color,
    size,
    opacity,
    gradient,
    pattern,
    eraserSize
  } = painting;

  return (
    <Box ml={4} aria-label="Painting Tools" display="flex" gap={3} alignItems="center">
      {toolMode === 'paint' && (
        <>
          <FormLabel mb="0">Brush:</FormLabel>
          <Select
            width="110px"
            value={brushType}
            onChange={(e) => dispatch(setBrushType(e.target.value))}
            aria-label="Brush Type Select"
          >
            <option value="round">Round</option>
            <option value="square">Square</option>
            <option value="stampGrass">Stamp: Grass</option>
            <option value="stampLeaves">Stamp: Leaves</option>
            <option value="stampRocks">Stamp: Rocks</option>
          </Select>

          <FormLabel mb="0">Color:</FormLabel>
          <Input
            width="60px"
            type="color"
            value={color}
            onChange={(e) => dispatch(setColor(e.target.value))}
            aria-label="Paint Color Picker"
          />

          <FormLabel mb="0">Size:</FormLabel>
          <Slider
            width="100px"
            min={1}
            max={50}
            value={size}
            onChange={(val) => dispatch(setSize(val))}
            aria-label="Brush Size Slider"
          >
            <SliderTrack>
              <SliderFilledTrack />
            </SliderTrack>
            <SliderThumb />
          </Slider>

          <FormLabel mb="0">Opacity:</FormLabel>
          <Slider
            width="80px"
            min={0.1}
            max={1}
            step={0.1}
            value={opacity}
            onChange={(val) => dispatch(setOpacity(val))}
            aria-label="Brush Opacity Slider"
          >
            <SliderTrack>
              <SliderFilledTrack />
            </SliderTrack>
            <SliderThumb />
          </Slider>

          <FormLabel mb="0">Gradient:</FormLabel>
          <Switch
            isChecked={gradient}
            onChange={(e) => dispatch(setGradient(e.target.checked))}
            aria-label="Gradient Mode Toggle"
          />

          <FormLabel mb="0">Pattern:</FormLabel>
          <Select
            width="100px"
            value={pattern || ''}
            onChange={(e) => dispatch(setPattern(e.target.value))}
            aria-label="Pattern Select"
          >
            <option value="">None</option>
            <option value="pavers">Pavers</option>
            <option value="grassTexture">Grass Texture</option>
          </Select>
        </>
      )}

      {toolMode === 'eraser' && (
        <>
          <FormLabel mb="0">Eraser Size:</FormLabel>
          <Slider
            width="100px"
            min={5}
            max={60}
            value={eraserSize}
            onChange={(val) => dispatch(setEraserSize(val))}
            aria-label="Eraser Size Slider"
          >
            <SliderTrack>
              <SliderFilledTrack />
            </SliderTrack>
            <SliderThumb />
          </Slider>
        </>
      )}
    </Box>
  );
}
