// File: client/src/store/authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../api/axiosInstance.js';

export const registerUser = createAsyncThunk(
'auth/registerUser',
async ({ username, password, email }, { rejectWithValue }) => {
try {
const res = await axios.post('/auth/register', { username, password, email });
return res.data;
} catch (err) {
return rejectWithValue(err.response?.data?.error || 'Registration failed.');
}
}

);

export const login = createAsyncThunk(
'auth/login',
async ({ username, password }, { rejectWithValue }) => {
try {
const res = await axios.post('/auth/login', { username, password });
return res.data;
} catch (err) {
return rejectWithValue(err.response?.data?.error || 'Login failed.');
}
}
);

export const logout = createAsyncThunk(
'auth/logout',
async (_, { rejectWithValue }) => {
try {

const res = await axios.post('/auth/logout');
return res.data;
} catch (err) {
return rejectWithValue(err.response?.data?.error || 'Logout failed.');
}
}
);

export const fetchUserProfile = createAsyncThunk(
'auth/fetchUserProfile',
async (_, { rejectWithValue }) => {
try {
const res = await axios.get('/auth/profile');
if (res.data.error) {
return rejectWithValue(res.data.error);
}
return res.data; // e.g. { id, username, email, is_admin, ... }
} catch (err) {
return

rejectWithValue(err.response?.data?.error || 'Fetch profile failed.');
}
}
);

export const initializeAuth = createAsyncThunk(
'auth/initializeAuth',
async (_, { dispatch }) => {
await dispatch(fetchUserProfile());
return true; // indicates "init done"
}
);

const authSlice = createSlice({
name: 'auth',
initialState: {
token: null,
user: null,
loading: false,
error: null,

initialized: false // We'll flip this after we attempt to fetch user
},
reducers: {
},
extraReducers: (builder) => {
builder
.addCase(registerUser.pending, (state) => {
state.loading = true;
state.error = null;
})
.addCase(registerUser.fulfilled, (state, action) => {
state.loading = false;
})
.addCase(registerUser.rejected, (state, action) => {
state.loading = false;
state.error = action.payload;
})

.addCase(login.pending, (state) => {
state.loading = true;

state.error = null;
})
.addCase(login.fulfilled, (state, action) => {
state.loading = false;
if (action.payload.success) {
state.token = action.payload.token;
state.user = action.payload.user;
} else {
state.error = action.payload.error || 'Server returned success=false.';
}
})
.addCase(login.rejected, (state, action) => {
state.loading = false;
state.error = action.payload;
})

.addCase(logout.pending, (state) => {
state.loading = true;
state.error = null;
})
.addCase(logout.fulfilled, (state, action) => {
state.loading = false;
state.token = null;

state.user = null;
})
.addCase(logout.rejected, (state, action) => {
state.loading = false;
state.error = action.payload;
})

.addCase(fetchUserProfile.pending, (state) => {
})
.addCase(fetchUserProfile.fulfilled, (state, action) => {
state.user = action.payload;
})
.addCase(fetchUserProfile.rejected, (state, action) => {
state.user = null;
})

.addCase(initializeAuth.fulfilled, (state, action) => {
state.initialized = true;
});
}

});

export default authSlice.reducer;
