// File: client/src/store/globalConfigSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../api/axiosInstance.js';

export const fetchAmazonConfig =

createAsyncThunk('globalConfig/fetchAmazon', async (_, { rejectWithValue }) => {
try {
const res = await axios.get('/globalconfig/amazon');
return res.data;
} catch (err) {
return rejectWithValue(err.response?.data?.error || 'Failed to fetch Amazon config');
}
});

export const fetchContainerMode = createAsyncThunk('globalConfig/fetchContainerMode', async (_, { rejectWithValue }) => {
try {
const res = await axios.get('/globalconfig/containerMode');
return res.data;

} catch (err) {
return rejectWithValue(err.response?.data?.error || 'Failed to fetch container mode');
}
});

export const updateContainerMode = createAsyncThunk('globalConfig/updateContainerMode', async (enabled, { rejectWithValue }) => {
try {
const res = await axios.post('/globalconfig/containerMode', { enabled });
return { enabled };
} catch (err) {
return rejectWithValue(err.response?.data?.error || 'Failed to update container mode');
}
});

const globalConfigSlice = createSlice({
name: 'globalConfig',

initialState: {
amazonEnabled: true,
containerModeEnabled: false,
loading: false,
error: null
},
reducers: {},
extraReducers: (builder) => {
builder
.addCase(fetchAmazonConfig.pending, (state) => { state.loading = true; state.error = null; })
.addCase(fetchAmazonConfig.fulfilled, (state, action) => {
state.loading = false;
state.amazonEnabled = action.payload.enabled;
})
.addCase(fetchAmazonConfig.rejected, (state, action) => {
state.loading = false;
state.error = action.payload;
})

.addCase(fetchContainerMode.pending, (state) => { state.loading = true; state.error = null; })
.addCase(fetchContainerMode.fulfilled, (state, action) => {
state.loading = false;
state.containerModeEnabled = action.payload.enabled;
})
.addCase(fetchContainerMode.rejected, (state, action) => {
state.loading = false;
state.error = action.payload;
})

.addCase(updateContainerMode.pending, (state) => {
state.loading = true;
state.error = null;
})
.addCase(updateContainerMode.fulfilled, (state, action) => {
state.loading = false;

state.containerModeEnabled = action.payload.enabled;
})
.addCase(updateContainerMode.rejected, (state, action) => {
state.loading = false;
state.error = action.payload;
});
}
});

export default globalConfigSlice.reducer;