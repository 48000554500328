// File: client/src/components/Garden/WeatherPanel.js

import React, { useState } from 'react';
import axios from '../../api/axiosInstance.js';
import { Box, Button, Input, Text } from '@chakra-ui/react';

function WeatherPanel() {
const [zip, setZip] = useState('');
const [result, setResult] = useState(null);
const [error, setError] = useState('');

const handleCheckWeather = async () => {
setError('');
setResult(null);

if (!zip) {
setError('Enter a ZIP code');
return;
}
try {
const res = await axios.get('/weather', { params: { zip } });
setResult(res.data);
} catch (err) {
console.error(err);
setError(err.response?.data?.error || 'Weather fetch failed');
}
};

return (
<Box p={3} border="1px solid #ccc" borderRadius="md" mb={3}>
<Text fontWeight="bold" mb={1}>Check Weather</Text>
<Box display="flex" mb={2} gap={2}>
<Input
placeholder="ZIP code"
size="sm"

value={zip}
onChange={(e) => setZip(e.target.value)}
width="100px"
/>
<Button
onClick={handleCheckWeather}
size="sm"
colorScheme="blue"
>
Go
</Button>
</Box>
{error && <Text color="red.500" mt={2}>{error}</Text>}
{result && (
<Box mt={2} fontSize="sm">
<Text>Result: {JSON.stringify(result)}</Text>
</Box>
)}
</Box>
);
}

export default WeatherPanel;
