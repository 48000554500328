// File: client/src/components/Garden/MyCustomObjects.js
import React, { useEffect, useState } from 'react';
import axios from '../../api/axiosInstance.js';
import { Box, Heading, UnorderedList, ListItem, Image, Spinner } from '@chakra-ui/react';

function MyCustomObjects() {
const [objects, setObjects] = useState([]);
const [loading, setLoading] = useState(false);

const loadData = async () => {
setLoading(true);
try {

const res = await axios.get('/customobjects/mine');
setObjects(res.data);
} catch (err) {
console.error('MyCustomObjects Error:', err);
} finally {
setLoading(false);
}
};

useEffect(() => {
loadData();
}, []);

return (
<Box p={2}>
<Heading as="h3" size="sm" mb={2}>My Custom Objects/Stamps</Heading>
{loading && <Spinner />}
<UnorderedList>
{objects.map(obj => (
<ListItem key={obj.id} mb={2}>
<Image
src={obj.icon_url || '/images/default_fallback.png'}
alt={obj.name}
boxSize="50px"
display="inline-block"
mr={2}
/>
<strong>{obj.name}</strong>
{obj.approved ? ' (Approved)' : ' (Not Approved)'}
</ListItem>
))}
</UnorderedList>
</Box>
);
}

export default MyCustomObjects;