// File: client/src/components/UserProfile/AchievementsPanel.js

import React, { useEffect, useState } from 'react';
import axios from '../../api/axiosInstance.js';
import { Box, Heading, Spinner, Text, UnorderedList, ListItem } from '@chakra-ui/react';

function AchievementsPanel() {
const [achievements, setAchievements] =

useState([]);
const [loading, setLoading] = useState(false);
const [error, setError] = useState('');

useEffect(() => {
const loadUserAchievements = async () => {
setLoading(true);
try {
const res = await axios.get('/achievements/user');
setAchievements(res.data);
} catch (err) {
console.error('Load Achievements Error:', err);
setError(err.response?.data?.error || 'Failed to load achievements');
} finally {
setLoading(false);
}
};
loadUserAchievements();
}, []);

return (

<Box mt={8}>
<Heading as="h3" size="md" mb={2}>My Achievements</Heading>
{loading && <Spinner />}
{error && <Text color="red.500" mb={2}>{error}</Text>}
{!loading && !error && achievements.length === 0 && (
<Text>No achievements unlocked yet.</Text>
)}
<UnorderedList>
{achievements.map((a) => (
<ListItem key={a.id}>
<strong>{a.name}</strong> - {a.description} (Unlocked on {new Date(a.unlocked_at).toLocaleDateString()})
</ListItem>
))}
</UnorderedList>
</Box>
);
}

export default AchievementsPanel;
