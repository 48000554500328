// File: client/src/components/Garden/VirtualizedList.js
import React from 'react';
import { FixedSizeList as List } from 'react-window';
import { Spinner, Box } from '@chakra-ui/react';

function VirtualizedList({ items, itemType, loading }) {

if (loading) {
return <Spinner />;
}
if (!items || items.length === 0) {
return <Box>No {itemType}s available.</Box>;
}

const Row = ({ index, style }) => {
const item = items[index];
return (
<div style={style}>
{item.name}
</div>
);
};

return (
<List
height={300}
width={220}
itemCount={items.length}
itemSize={35}
itemData={items}
style={{ border: '1px solid #ccc' }}

>
{Row}
</List>
);
}

export default VirtualizedList;