// File: client/src/components/Garden/ExportModal.js

import React, { useRef, useEffect } from 'react';
import {
Modal,
ModalOverlay,
ModalContent,
ModalHeader,
ModalBody,
ModalFooter,
Button,
Textarea,
useToast
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';

export default function ExportModal({ isOpen, onClose }) {
const toast = useToast();
const objects = useSelector((state) => state.garden.objects);
const shapes = useSelector((state) => state.garden.shapes);
const canvasTransform = useSelector((state) => state.garden.canvasTransform);
const layers = useSelector((state) => state.garden.layers);

const exportCanvasRef = useRef(null);

useEffect(() => {
if (!isOpen) return;
const ctx = exportCanvasRef.current.getContext('2d');
ctx.fillStyle = '#ffffff';
ctx.fillRect(0, 0, 1000, 1000);
shapes.forEach((s) => {
if (s.type === 'paint') {
const pts = s.points || [];

if (pts.length < 2) return;
ctx.save();
ctx.globalAlpha = s.opacity || 1.0;
ctx.strokeStyle = s.color || '#000';
ctx.lineWidth = s.size || 2;
ctx.beginPath();
ctx.moveTo(pts[0].x, pts[0].y);
for (let i = 1; i < pts.length; i++) {
ctx.lineTo(pts[i].x, pts[i].y);
}
ctx.stroke();
ctx.restore();
} else if (s.type === 'measure') {
}
});
}, [isOpen, shapes]);

const handleExportJSON = () => {
const data = {
objects,
shapes,
layers,
canvasTransform

};
const blob = new Blob([JSON.stringify(data, null, 2)], { type: 'application/json' });
const url = URL.createObjectURL(blob);
const a = document.createElement('a');
a.href = url;
a.download = 'garden_design.json';
a.click();
URL.revokeObjectURL(url);
toast({ title: 'Design exported as JSON.', status: 'success', isClosable: true });
};

const handleExportPNG = () => {
const url = exportCanvasRef.current.toDataURL('image/png');
const a = document.createElement('a');
a.href = url;
a.download = 'garden_design.png';
a.click();
toast({ title: 'Design exported as PNG.', status: 'success', isClosable: true });
};

return (
<Modal isOpen={isOpen} onClose={onClose} isCentered size="xl">
<ModalOverlay />
<ModalContent>
<ModalHeader>Export Garden Design</ModalHeader>
<ModalBody>
<Textarea
value="Use the buttons below to export as JSON or PNG."
readOnly
mb={3}
/>
{/* Hidden offscreen canvas for PNG generation */}
<canvas
ref={exportCanvasRef}
width={1000}
height={1000}
style={{ display: 'none' }}
/>
</ModalBody>

<ModalFooter>
<Button onClick={handleExportJSON} colorScheme="blue" mr={3}>
Export JSON
</Button>
<Button onClick={handleExportPNG} colorScheme="green" mr={3}>
Export PNG
</Button>
<Button variant="ghost" onClick={onClose}>
Close
</Button>
</ModalFooter>
</ModalContent>
</Modal>
);
}
