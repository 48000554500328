// File: client/src/components/Garden/CollaboratorsOverlay.js

import React from 'react';
import { useSelector } from 'react-redux';
import { FaPaintBrush, FaRuler, FaCommentDots, FaEraser } from 'react-icons/fa';
import { Avatar } from '@chakra-ui/react';

/**
 * This overlay is *not* inside the scaled <div>.
 * We position each collaborator in absolute screen coords
 * by applying (offsetX + x * scale, offsetY + y * scale).
 * This ensures they line up with the same transforms you see on GardenCanvas.
 */
export default function CollaboratorsOverlay() {
  const cursors = useSelector((state) => state.collab.cursors);
  const { offsetX, offsetY, scale } = useSelector(
    (state) => state.garden.canvasTransform
  );

  function getToolIcon(tool) {
    switch (tool) {
      case 'paint': return <FaPaintBrush style={{ marginLeft: 4 }} />;
      case 'measure': return <FaRuler style={{ marginLeft: 4 }} />;
      case 'eraser': return <FaEraser style={{ marginLeft: 4 }} />;
      default: return null;
    }
  }

  function getChatStatusIcon(chatStatus) {
    if (chatStatus === 'typing') {
      return <FaCommentDots style={{ marginLeft: 4 }} />;
    }
    return null;
  }

  return (
    <div
      style={{
        pointerEvents: 'none',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        // no transform here; we do manual math to find screen coords
        zIndex: 9998
      }}
    >
      {Object.entries(cursors).map(([userId, c]) => {
        // c.x, c.y are in canvas local coords
        // Convert to screen coords:
        const screenX = offsetX + c.x * scale;
        const screenY = offsetY + c.y * scale;

        return (
          <div
            key={userId}
            style={{
              position: 'absolute',
              left: screenX,
              top: screenY,
              transform: 'translate(-50%, -50%)',
              display: 'flex',
              alignItems: 'center',
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
              padding: '2px 6px',
              borderRadius: 4,
              fontSize: '14px',
              fontWeight: 'bold',
              color: c.color || 'magenta',
              border: '1px solid #ddd',
              marginLeft: 0
            }}
          >
            <Avatar
              name={c.username || 'User'}
              size="xs"
              src={c.avatarUrl || ''}
              marginRight={2}
            />
            {c.username || 'User'}
            {getToolIcon(c.tool)}
            {getChatStatusIcon(c.chatStatus)}
            <span style={{ marginLeft: 8, fontSize: '12px', color: '#333' }}>
              ({Math.round(c.x)}, {Math.round(c.y)})
            </span>
          </div>
        );
      })}
    </div>
  );
}
