// File: client/src/components/Garden/NotesPanel.js

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import axios from '../../api/axiosInstance.js';
import {
Box,
Heading,
Spinner,
Text,
Input,
Button,
Textarea,
Switch,
FormControl,
FormLabel
} from '@chakra-ui/react';

function NotesPanel() {
const currentProject = useSelector((state) => state.projects.currentProject);
const [notes, setNotes] = useState([]);
const [loading, setLoading] = useState(false);
const [error, setError] = useState('');
const [content, setContent] = useState('');
const [isPublic, setIsPublic] = useState(false);
const [message, setMessage] = useState('');

const loadNotes = async () => {
if (!currentProject) return;
setLoading(true);
setError('');
setMessage('');
try {
const res = await axios.get('/notes', {
params: { projectId: currentProject.id }
});
setNotes(res.data);
} catch (err) {
console.error('Load notes error:', err);
setError(err.response?.data?.error || 'Error loading notes.');
} finally {
setLoading(false);
}
};

useEffect(() => {
loadNotes();
}, [currentProject]);

const handleAddNote = async () => {

if (!content.trim()) return;
setMessage('');
setError('');
try {
await axios.post('/notes', {
projectId: currentProject.id,
content,
is_public: isPublic
});
setContent('');
setIsPublic(false);
setMessage('Note added.');
loadNotes();
} catch (err) {
console.error('Add note error:', err);
setError(err.response?.data?.error || 'Error adding note.');
}
};

const handleDeleteNote = async (id) => {
try {
await axios.delete(`/notes/${id}`);

setMessage('Note deleted.');
loadNotes();
} catch (err) {
console.error('Delete note error:', err);
setError(err.response?.data?.error || 'Error deleting note.');
}
};

return (
<Box p={3} borderTop="1px solid #ccc">
<Heading as="h3" size="sm" mb={2}>Notes</Heading>
{loading && <Spinner />}
{error && <Text color="red.500" mb={2}>{error}</Text>}
{message && <Text color="green.600" mb={2}>{message}</Text>}

<Box mb={3}>
<Textarea
placeholder="Write a note..."
value={content}
onChange={(e) => setContent(e.target.value)}

rows={3}
/>
<FormControl display="flex" alignItems="center" mt={2}>
<FormLabel htmlFor="public-switch" mb="0" fontSize="sm">
Public?
</FormLabel>
<Switch
id="public-switch"
isChecked={isPublic}
onChange={(e) => setIsPublic(e.target.checked)}
size="sm"
colorScheme="teal"
/>
</FormControl>
<Button mt={2} size="sm" colorScheme="blue" onClick={handleAddNote}>
Add Note
</Button>
</Box>

<Box>
{notes.length === 0 && !loading && (
<Text fontSize="sm">No notes yet.</Text>
)}
{notes.map((note) => (
<Box
key={note.id}
p={2}
mb={2}
border="1px solid #ddd"
borderRadius="md"
>
<Text fontSize="sm">{note.content}</Text>
<Text fontSize="xs" color="gray.600">
{note.is_public ? '(Public)' : '(Private)'}
</Text>
<Button
size="xs"
colorScheme="red"
mt={1}
onClick={() => handleDeleteNote(note.id)}
>
Delete

</Button>
</Box>
))}
</Box>
</Box>
);
}

export default NotesPanel;
