// File: client/src/components/Garden/RealTimeCollab.js

import React, { useEffect } from 'react';
import io from 'socket.io-client';
import { useDispatch, useSelector } from 'react-redux';
import { applyCanvasUpdate } from '../../store/gardenSlice.js';
import {
  addRemotePartialStroke,
  finalizeRemoteStroke
} from '../../store/paintingSlice.js';
import { setCollaboratorCursor } from '../../store/collabSlice.js';

let socket = null;

export function getSocket() {
  return socket;
}

export default function RealTimeCollab({ projectId }) {
  const dispatch = useDispatch();
  const partialsCleanupInterval = useSelector(
    (state) => state.userSettings.partialsCleanupInterval
  ) || 30000; // 30s default

  useEffect(() => {
    if (!projectId) return;

    // IMPORTANT FIX:
    // Use your production domain + path for socket.io,
    // so wss://alaskaspot.com/socket.io works.
    if (!socket) {
      // If REACT_APP_API_URL is not set, fallback to your production domain
      const baseURL =
        process.env.REACT_APP_API_URL || 'https://alaskaspot.com';

      socket = io(baseURL, {
        // Ensures cookies are sent, if any
        withCredentials: true,

        // Match server's path setting
        path: '/socket.io',

        // Force WebSocket, fallback to polling if needed
        transports: ['websocket', 'polling']
      });
    }

    socket.emit('joinProject', projectId);

    const handlePartialStroke = (payload) => {
      if (!payload || payload.projectId !== projectId) return;
      const timeNow = Date.now();
      dispatch(
        addRemotePartialStroke({
          ...payload,
          updatedAt: timeNow
        })
      );
    };
    socket.on('partialStroke', handlePartialStroke);

    const handleFinalStroke = (payload) => {
      if (!payload || payload.projectId !== projectId) return;
      dispatch(finalizeRemoteStroke(payload));
    };
    socket.on('finalStroke', handleFinalStroke);

    const handleCanvasUpdate = (payload) => {
      dispatch(applyCanvasUpdate(payload));
    };
    socket.on('canvasUpdate', handleCanvasUpdate);

    const handleCursor = (payload) => {
      if (payload.projectId !== projectId) return;
      dispatch(setCollaboratorCursor(payload));
    };
    socket.on('cursorMove', handleCursor);

    socket.on('chatMessage', () => {
      // Chat messages handled in ChatWindow
    });

    const cleanup = setInterval(() => {
      dispatch({
        type: 'painting/cleanupRemotePartials',
        payload: { maxAge: 2 * 60_000 } // 2 minutes
      });
    }, partialsCleanupInterval);

    return () => {
      socket.off('partialStroke', handlePartialStroke);
      socket.off('finalStroke', handleFinalStroke);
      socket.off('canvasUpdate', handleCanvasUpdate);
      socket.off('cursorMove', handleCursor);
      socket.off('chatMessage');
      clearInterval(cleanup);
    };
  }, [dispatch, projectId, partialsCleanupInterval]);

  return null;
}

