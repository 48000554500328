// File: client/src/components/Garden/GardenToolbar.js

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setToolMode, toggleSnapToGrid } from '../../store/gardenSlice.js';
import PaintingTools from './PaintingTools.js';
import {
  Box,
  Button,
  Input,
  Switch,
  FormControl,
  FormLabel,
  Tooltip,
  useToast,
  HStack
} from '@chakra-ui/react';
import axios from '../../api/axiosInstance.js';
import { setCurrentProject } from '../../store/projectsSlice.js';
import { getSocket } from './RealTimeCollab.js';
import ExportModal from './ExportModal.js';

export default function GardenToolbar({
  onStartCollab,
  collabActive,
  onToggleSidebar,
  onOpenImportModal,
  onToggleRightSidebar
  // [FIXED] Removed onToggleWeather, onToggleSeasons, onToggleNotes props to avoid confusion
}) {
  const dispatch = useDispatch();
  const toast = useToast();

  const toolMode = useSelector((state) => state.garden.toolMode);
  const currentProject = useSelector((state) => state.projects.currentProject);
  const user = useSelector((state) => state.auth.user);
  const myCursor = useSelector((state) => state.collab.cursors[user?.id] || {});
  const snapToGrid = useSelector((state) => state.garden.snapToGrid);

  const socket = getSocket();

  const [shareEmail, setShareEmail] = useState('');
  const [shareMsg, setShareMsg] = useState('');
  const [exportOpen, setExportOpen] = useState(false);

  const handleModeToggle = (mode) => {
    const newMode = toolMode === mode ? 'none' : mode;
    dispatch(setToolMode(newMode));

    if (socket && user) {
      socket.emit('cursorMove', {
        projectId: currentProject?.id,
        userId: user.id,
        username: user.username,
        color: user.cursorColor || '#ff00ff',
        x: myCursor.x || 0,
        y: myCursor.y || 0,
        tool: newMode,
        chatStatus: 'idle'
      });
    }
  };

  const doUndo = async () => {
    if (!currentProject) return;
    try {
      const res = await axios.post(`/projects/${currentProject.id}/undo`);
      if (res.data && !res.data.error) {
        dispatch(setCurrentProject(res.data));
        toast({ title: 'Undo successful', status: 'success' });
      } else {
        toast({
          title: 'Undo failed',
          description: res.data?.error,
          status: 'error'
        });
      }
    } catch (err) {
      console.error(err);
      toast({ title: 'Undo error', description: err.message, status: 'error' });
    }
  };

  const doRedo = async () => {
    if (!currentProject) return;
    try {
      const res = await axios.post(`/projects/${currentProject.id}/redo`);
      if (res.data && !res.data.error) {
        dispatch(setCurrentProject(res.data));
        toast({ title: 'Redo successful', status: 'success' });
      } else {
        toast({
          title: 'Redo not available',
          description: res.data?.error,
          status: 'warning'
        });
      }
    } catch (err) {
      console.error(err);
      toast({ title: 'Redo error', description: err.message, status: 'error' });
    }
  };

  const handleShareProject = async () => {
    setShareMsg('');
    if (!shareEmail) {
      setShareMsg('Please enter an email to share with.');
      return;
    }
    if (!currentProject) {
      setShareMsg('No project selected.');
      return;
    }

    try {
      const res = await axios.post(`/projects/${currentProject.id}/share`, {
        email: shareEmail
      });
      if (res.data.success) {
        setShareMsg('Invitation sent successfully.');
        toast({ title: 'Project shared', status: 'success' });
      } else {
        setShareMsg(res.data.error || 'Error sharing project.');
      }
    } catch (err) {
      console.error('Share Error:', err);
      setShareMsg(err.response?.data?.error || 'Failed to share project.');
    }
  };

  const toggleSnap = () => {
    dispatch(toggleSnapToGrid(!snapToGrid));
  };

  return (
    <Box
      aria-label="Garden Toolbar"
      bg="gray.100"
      _dark={{ bg: 'gray.900' }}
      p={2}
      display="flex"
      gap={2}
      alignItems="center"
      flexWrap="wrap"
      boxShadow="sm"
    >
      <Tooltip label="Draw freehand strokes" aria-label="Paint Tool">
        <Button
          onClick={() => handleModeToggle('paint')}
          colorScheme={toolMode === 'paint' ? 'green' : 'blue'}
          size="sm"
        >
          {toolMode === 'paint' ? 'Stop Painting' : 'Paint'}
        </Button>
      </Tooltip>

      <Tooltip label="Measure distances on the canvas" aria-label="Measure Tool">
        <Button
          onClick={() => handleModeToggle('measure')}
          colorScheme={toolMode === 'measure' ? 'green' : 'blue'}
          size="sm"
        >
          {toolMode === 'measure' ? 'Stop Measuring' : 'Measure'}
        </Button>
      </Tooltip>

      <Tooltip label="Drag or pan the canvas" aria-label="Pan Tool">
        <Button
          onClick={() => handleModeToggle('dragSelect')}
          colorScheme={toolMode === 'dragSelect' ? 'green' : 'blue'}
          size="sm"
        >
          {toolMode === 'dragSelect' ? 'Stop Panning' : 'Pan'}
        </Button>
      </Tooltip>

      <Tooltip label="Erase paint strokes" aria-label="Eraser Tool">
        <Button
          onClick={() => handleModeToggle('eraser')}
          colorScheme={toolMode === 'eraser' ? 'green' : 'blue'}
          size="sm"
        >
          {toolMode === 'eraser' ? 'Stop Erasing' : 'Eraser'}
        </Button>
      </Tooltip>

      {toolMode === 'paint' || toolMode === 'eraser' ? <PaintingTools /> : null}

      <Button onClick={doUndo} size="sm" colorScheme="orange" variant="outline">
        Undo
      </Button>
      <Button onClick={doRedo} size="sm" colorScheme="orange" variant="outline">
        Redo
      </Button>

      {!collabActive && (
        <Button ml={2} colorScheme="teal" size="sm" onClick={onStartCollab}>
          Start Collab
        </Button>
      )}

      {/* Share project UI */}
      <HStack spacing={2} align="center">
        <Input
          placeholder="Share Email"
          size="sm"
          value={shareEmail}
          onChange={(e) => setShareEmail(e.target.value)}
          width="140px"
        />
        <Button size="sm" colorScheme="blue" onClick={handleShareProject}>
          Share
        </Button>
      </HStack>
      {shareMsg && (
        <Box color="green.600" fontSize="sm">
          {shareMsg}
        </Box>
      )}

      <FormControl display="flex" alignItems="center" width="auto">
        <FormLabel htmlFor="snap-to-grid" mb="0" fontSize="sm" mr={2}>
          Snap
        </FormLabel>
        <Switch
          id="snap-to-grid"
          isChecked={snapToGrid}
          onChange={toggleSnap}
          size="sm"
          colorScheme="teal"
        />
      </FormControl>

      {/* Export Button */}
      <Button
        size="sm"
        variant="outline"
        colorScheme="purple"
        onClick={() => setExportOpen(true)}
      >
        Export
      </Button>
      {exportOpen && <ExportModal isOpen={exportOpen} onClose={() => setExportOpen(false)} />}

      {/* Import Button */}
      <Button
        size="sm"
        variant="outline"
        colorScheme="purple"
        onClick={onOpenImportModal}
      >
        Import
      </Button>

      {/* Left Sidebar Toggle */}
      <Button size="sm" variant="outline" onClick={onToggleSidebar}>
        Left Sidebar
      </Button>

      {/* [FIXED] Removed these since the parent doesn't pass them:
            <Button onClick={onToggleWeather} ...> 
            <Button onClick={onToggleSeasons} ...>
            <Button onClick={onToggleNotes} ...>
         */}

      {/* Right Sidebar Toggle */}
      <Button size="sm" variant="outline" onClick={onToggleRightSidebar}>
        Right Sidebar
      </Button>
    </Box>
  );
}
