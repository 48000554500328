// File: client/src/components/Garden/CursorPositionOverlay.js
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

/**
 * Shows the current pointer coordinate (in “canvas space”) at the bottom right,
 * now stacking X and Y, and showing scale on its own line. Also adds 'toolMode'
 * to the dependency array to ensure it updates whenever the tool changes.
 */
export default function CursorPositionOverlay() {
  const { offsetX, offsetY, scale } = useSelector((state) => state.garden.canvasTransform);
  const toolMode = useSelector((state) => state.garden.toolMode);

  const containerRef = useRef(null);
  const [coords, setCoords] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handler = (e) => {
      const container = containerRef.current;
      if (!container) return;
      const rect = container.getBoundingClientRect();

      const localX = (e.clientX - rect.left - offsetX) / scale;
      const localY = (e.clientY - rect.top - offsetY) / scale;
      setCoords({ x: localX, y: localY });
    };

    window.addEventListener('pointermove', handler);
    return () => window.removeEventListener('pointermove', handler);
    // include toolMode so the overlay re-initializes if needed
  }, [offsetX, offsetY, scale, toolMode]);

  return (
    <div
      ref={containerRef}
      style={{
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        pointerEvents: 'none'
      }}
    >
      <div
        style={{
          position: 'absolute',
          right: '10px',
          bottom: '10px',
          backgroundColor: 'rgba(255,255,255,0.7)',
          border: '1px solid #ccc',
          borderRadius: 4,
          padding: '4px 8px',
          fontSize: '12px',
          color: '#333',
          minWidth: '120px',
          zIndex: 9999
        }}
      >
        X={Math.round(coords.x)}<br />
        Y={Math.round(coords.y)}<br />
        Scale: {scale.toFixed(2)}x
      </div>
    </div>
  );
}
