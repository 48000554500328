// File: client/src/components/Garden/ObjectInspector.js
import React from 'react';
import { Box, Heading } from '@chakra-ui/react';

function ObjectInspector() {
return (
<Box p={2}>
<Heading as="h3" size="sm" mb={2}>Object Inspector</Heading>
{/* Show details for selected item, allow property editing */}

</Box>
);
}

export default ObjectInspector;