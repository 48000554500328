// File: client/src/store/customObjectsSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../api/axiosInstance.js';

export const fetchUserCustomObjects = createAsyncThunk('customObjects/fetchMine', async (_, { rejectWithValue }) => {
try {

const res = await axios.get('/customobjects/mine');
return res.data;
} catch (err) {
return rejectWithValue(err.response?.data?.error || 'Failed to fetch custom objects');
}
});

const customObjectsSlice = createSlice({
name: 'customObjects',
initialState: {
items: [],
loading: false,
error: null
},
reducers: {},
extraReducers: (builder) => {
builder
.addCase(fetchUserCustomObjects.pending, (state) => { state.loading = true; state.error = null; })

.addCase(fetchUserCustomObjects.fulfilled, (state, action) => {
state.loading = false;
state.items = action.payload;
})
.addCase(fetchUserCustomObjects.rejected, (state, action) => {
state.loading = false;
state.error = action.payload;
});
}
});

export default customObjectsSlice.reducer;