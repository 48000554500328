// File: client/src/components/Garden/GardenPage.js

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Flex,
  Button,
  Text,
  useDisclosure,
  useColorModeValue,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon
} from '@chakra-ui/react';

import GardenToolbar from './GardenToolbar.js';
import GardenCanvas from './GardenCanvas.js';
import ChatWindow from './ChatWindow.js';
import RealTimeCollab, { getSocket } from './RealTimeCollab.js';
import Sidebar from './Sidebar.js';
import LayerManager from './LayerManager.js';
import ObjectInspector from './ObjectInspector.js';
import ItemListPanel from './ItemListPanel.js';
import MyCustomObjects from './MyCustomObjects.js';
import TrashPanel from './TrashPanel.js';
import WeatherPanel from './WeatherPanel.js';
import useAutoSave from '../../hooks/useAutoSave.js';
import CursorPositionOverlay from './CursorPositionOverlay.js';
import ImportModal from './ImportModal.js';
import SeasonsPanel from './SeasonsPanel.js';
import NotesPanel from './NotesPanel.js';
import SettingsPanel from './SettingsPanel.js';
import LocalCursorOverlay from './LocalCursorOverlay.js';
import CollaboratorsOverlay from './CollaboratorsOverlay.js';

function GardenPage() {
  const pageBg = useColorModeValue('gray.200', 'gray.800');
  const sidebarBg = useColorModeValue('white', 'gray.700');

  const currentProject = useSelector((state) => state.projects.currentProject);
  const socket = getSocket();

  const [collabActive, setCollabActive] = useState(false);
  // Keep a single leftSidebar toggle
  const [sidebarOpen, setSidebarOpen] = useState(true);
  // Keep a single rightSidebar toggle
  const [rightSidebarOpen, setRightSidebarOpen] = useState(true);

  // Import modal
  const [importOpen, setImportOpen] = useState(false);

  const objects = useSelector((state) => state.garden.objects);
  const shapes = useSelector((state) => state.garden.shapes);
  useAutoSave(currentProject, objects, shapes);

  if (!currentProject) {
    return (
      <Box p={4}>
        No project selected. Go to Dashboard to pick a project or create a new one.
      </Box>
    );
  }

  const handleStartCollab = () => {
    setCollabActive(true);
  };
  const handleStopCollab = () => {
    setCollabActive(false);
  };

  const toggleSidebar = () => {
    setSidebarOpen((prev) => !prev);
  };
  const toggleRightSidebar = () => {
    setRightSidebarOpen((prev) => !prev);
  };

  const openImportModal = () => {
    setImportOpen(true);
  };

  return (
    <Flex direction="row" minH="100vh" bg={pageBg}>
      {/* LEFT SIDEBAR: for plants/trees/structures */}
      <Box
        display={sidebarOpen ? 'block' : 'none'}
        position="sticky"
        top="0"
        h="100vh"
        overflowY="auto"
        w={['100%', '250px']}
        borderRight={['none', '1px solid #ccc']}
        borderBottom={['1px solid #ccc', 'none']}
        bg={sidebarBg}
        transition="width 0.3s ease"
        zIndex={10}
      >
        <Sidebar />
      </Box>

      {/* MAIN AREA */}
      <Box flex="1" position="relative" minW={0}>
        <Box position="relative" zIndex={999}>
          <GardenToolbar
            onStartCollab={handleStartCollab}
            collabActive={collabActive}
            onToggleSidebar={toggleSidebar}
            onOpenImportModal={openImportModal}
            onToggleRightSidebar={toggleRightSidebar}
          />
        </Box>
        <GardenCanvas />
        <LocalCursorOverlay />
        <CollaboratorsOverlay />
        <CursorPositionOverlay />
        <RealTimeCollab projectId={currentProject.id} />

        {collabActive && (
          <ChatWindow
            projectId={currentProject.id}
            socket={socket}
            onStopCollab={handleStopCollab}
          />
        )}
      </Box>

      {/* RIGHT SIDEBAR: all collapsible panels inside an Accordion */}
      <Box
        display={rightSidebarOpen ? 'flex' : 'none'}
        flexDirection="column"
        position="sticky"
        top="0"
        h="100vh"
        w={['100%', '320px']}
        borderLeft={['none', '1px solid #ccc']}
        borderTop={['1px solid #ccc', 'none']}
        overflowY="auto"
        bg={sidebarBg}
        zIndex={10}
      >
        <Accordion allowMultiple defaultIndex={[]}>
          <AccordionItem>
            <AccordionButton>
              <Box flex="1" textAlign="left">Settings</Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
              <SettingsPanel />
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionButton>
              <Box flex="1" textAlign="left">Weather</Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
              <WeatherPanel />
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionButton>
              <Box flex="1" textAlign="left">Layers</Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
              <LayerManager />
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionButton>
              <Box flex="1" textAlign="left">Objects</Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
              <ObjectInspector />
              <ItemListPanel />
              <MyCustomObjects />
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionButton>
              <Box flex="1" textAlign="left">Trash</Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
              <TrashPanel />
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionButton>
              <Box flex="1" textAlign="left">Seasons</Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
              <SeasonsPanel />
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionButton>
              <Box flex="1" textAlign="left">Notes</Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
              <NotesPanel />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Box>

      {/* Import Modal */}
      {importOpen && (
        <ImportModal
          isOpen={importOpen}
          onClose={() => setImportOpen(false)}
        />
      )}
    </Flex>
  );
}

export default GardenPage;

