// File: client/src/components/Garden/GardenCanvas.js

import React, {
  useEffect,
  useRef,
  useState,
  useCallback
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  setObjects,
  setCanvasTransform,
  addPaintStroke,
  removeShape,
  setPointerPosition,
  setShapes
} from '../../store/gardenSlice.js';
import { finalizeStroke } from '../../store/paintingSlice.js';
import { toast } from 'react-hot-toast';
import axiosInstance from '../../api/axiosInstance.js';
import { getSocket } from './RealTimeCollab.js';
import { canStackContainers } from './ContainerLogic.js';
import './GardenCanvas.css';
import { useColorModeValue } from '@chakra-ui/react';

export default function GardenCanvas() {
  const dispatch = useDispatch();
  const socket = getSocket();
  const user = useSelector((state) => state.auth.user);
  const projectId = useSelector((state) => state.projects.currentProject?.id);

  const {
    width: projectWidth = 50,
    height: projectHeight = 50,
    unit: projectUnit = 'units'
  } = useSelector((state) => state.garden);

  const pxPerUnit = 20;
  const baseProjectPxWidth = projectWidth * pxPerUnit;
  const baseProjectPxHeight = projectHeight * pxPerUnit;

  const {
    toolMode,
    objects,
    shapes,
    canvasTransform,
    layers,
    snapToGrid,
    gridSize
  } = useSelector((state) => state.garden);
  const { offsetX, offsetY, scale } = canvasTransform;

  const painting = useSelector((state) => state.painting);
  const {
    brushType,
    color: brushColor,
    size: brushSize,
    opacity,
    gradient,
    pattern,
    eraserSize
  } = painting;

  const containerModeEnabled = useSelector(
    (state) => state.globalConfig.containerModeEnabled
  );
  const userSettings = useSelector((state) => state.userSettings);
  const { eraserMode } = userSettings;
  const { keyBinds } = userSettings;

  // [FIXED] In case the user set a custom straight-line key, we handle SHIFT or that key.
  // We also store them as lowercase for consistency.
  const customStraightLineKey = (keyBinds?.straightLine || 'x').toLowerCase();

  // State tracking
  const [isPainting, setIsPainting] = useState(false);
  const [isMeasuring, setIsMeasuring] = useState(false);
  const [isPanning, setIsPanning] = useState(false);
  const [isErasing, setIsErasing] = useState(false);

  // [FIXED] Straight line mode toggles if SHIFT or custom key is pressed.
  const [straightLineActive, setStraightLineActive] = useState(false);

  const containerRef = useRef(null);
  const gridRef = useRef(null);
  const paintCanvasRef = useRef(null);
  const measureCanvasRef = useRef(null);

  const panStart = useRef({ x: 0, y: 0 });
  const initOffset = useRef({ x: offsetX, y: offsetY });

  const pointers = useRef(new Map());
  const initialDistance = useRef(0);
  const initialScale = useRef(scale);
  const initialMid = useRef({ x: 0, y: 0 });
  const startOffset = useRef({ x: offsetX, y: offsetY });

  const paintPointsQueue = useRef([]);
  const measureStartCoord = useRef(null);

  const [draggingObjectId, setDraggingObjectId] = useState(null);
  const dragOffsetRef = useRef({ x: 0, y: 0 });

  const animationRef = useRef(null);

  const canvasBg = useColorModeValue('#ffffff', '#1A202C');
  const gridLineColor = useColorModeValue('#cbd5e0', '#4A5568');

  // Main rendering loop for shapes
  const renderLoop = useCallback(() => {
    const paintCtx = paintCanvasRef.current?.getContext('2d');
    const measureCtx = measureCanvasRef.current?.getContext('2d');

    if (!paintCtx || !measureCtx) {
      animationRef.current = requestAnimationFrame(renderLoop);
      return;
    }

    // Clear each canvas
    paintCtx.clearRect(0, 0, baseProjectPxWidth, baseProjectPxHeight);
    measureCtx.clearRect(0, 0, baseProjectPxWidth, baseProjectPxHeight);

    // Render painting layer if visible
    if (isLayerVisible('painting')) {
      shapes.forEach((stroke) => {
        if (stroke.type === 'paint') {
          drawPaintStroke(paintCtx, stroke);
        }
      });

      // If user is currently painting, show the partial stroke
      if (isPainting && paintPointsQueue.current.length >= 2) {
        drawPaintStroke(paintCtx, {
          type: 'paint',
          brushType,
          color: brushColor,
          size: brushSize,
          opacity,
          gradient,
          pattern,
          points: paintPointsQueue.current
        });
      }
    }

    // Render measuring lines if visible
    if (isLayerVisible('measuring')) {
      shapes.forEach((line) => {
        if (line.type === 'measure') {
          drawMeasureLine(measureCtx, line);
        }
      });

      if (isMeasuring && measureStartCoord.current) {
        if (pointers.current.size === 1) {
          const [event] = [...pointers.current.values()];
          if (event) {
            const end = screenToLocal(event.clientX, event.clientY);
            drawMeasureLine(measureCtx, {
              type: 'measure',
              points: [measureStartCoord.current, end],
              color: '#000',
              size: 2
            });
          }
        }
      }
    }

    animationRef.current = requestAnimationFrame(renderLoop);
  }, [
    baseProjectPxWidth,
    baseProjectPxHeight,
    shapes,
    isPainting,
    brushType,
    brushColor,
    brushSize,
    opacity,
    gradient,
    pattern,
    isMeasuring
  ]);

  function isLayerVisible(id) {
    const layer = layers.find((l) => l.id === id);
    return layer ? layer.visible : false;
  }

  useEffect(() => {
    animationRef.current = requestAnimationFrame(renderLoop);
    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [renderLoop]);

  // Dynamically set grid background based on scale and offset
  useEffect(() => {
    if (!gridRef.current) return;
    const s = Math.round(scale * 20);
    const xPos = Math.round(-offsetX * scale);
    const yPos = Math.round(-offsetY * scale);

    gridRef.current.style.backgroundSize = `${s}px ${s}px`;
    gridRef.current.style.backgroundPosition = `${xPos}px ${yPos}px`;
    gridRef.current.style.backgroundImage = `
      linear-gradient(to right, ${gridLineColor} 1px, transparent 1px),
      linear-gradient(to bottom, ${gridLineColor} 1px, transparent 1px)
    `;
  }, [scale, offsetX, offsetY, gridLineColor]);

  // If pointer goes up outside the canvas
  useEffect(() => {
    function handleGlobalPointerUp(e) {
      if (isPainting) onPointerUp(e);
      if (isMeasuring) onPointerUp(e);
      if (isPanning) onPointerUp(e);
      if (isErasing) onPointerUp(e);
    }
    window.addEventListener('pointerup', handleGlobalPointerUp);
    return () => {
      window.removeEventListener('pointerup', handleGlobalPointerUp);
    };
  }, [isPainting, isMeasuring, isPanning, isErasing]);

  function onPointerDown(e) {
    e.target.setPointerCapture?.(e.pointerId);
    pointers.current.set(e.pointerId, e);

    if (toolMode === 'eraser') {
      setIsErasing(true);
    }

    if (containerModeEnabled && toolMode === 'objects') {
      const localPt = screenToLocal(e.clientX, e.clientY);
      for (let i = 0; i < objects.length; i++) {
        const obj = objects[i];
        const w = obj.width || 50;
        const h = obj.height || 50;
        if (
          localPt.x >= obj.x &&
          localPt.x <= obj.x + w &&
          localPt.y >= obj.y &&
          localPt.y <= obj.y + h
        ) {
          setDraggingObjectId(i);
          dragOffsetRef.current = {
            x: localPt.x - obj.x,
            y: localPt.y - obj.y
          };
          break;
        }
      }
    }

    // If two pointers, pinch-zoom
    if (pointers.current.size === 2) {
      const [p1, p2] = [...pointers.current.values()];
      initialDistance.current = Math.hypot(
        p2.clientX - p1.clientX,
        p2.clientY - p1.clientY
      );
      initialScale.current = scale;
      initialMid.current = {
        x: (p1.clientX + p2.clientX) / 2,
        y: (p1.clientY + p2.clientY) / 2
      };
      startOffset.current = { x: offsetX, y: offsetY };
      return;
    }

    // Single pointer
    if (pointers.current.size === 1) {
      const coords = screenToLocal(e.clientX, e.clientY);
      dispatch(setPointerPosition(coords));

      if (toolMode === 'paint') {
        setIsPainting(true);
        paintPointsQueue.current = [coords];
      } else if (toolMode === 'measure') {
        setIsMeasuring(true);
        measureStartCoord.current = coords;
      } else if (toolMode === 'dragSelect') {
        setIsPanning(true);
        panStart.current = { x: e.clientX, y: e.clientY };
        initOffset.current = { x: offsetX, y: offsetY };
      } else if (toolMode === 'eraser') {
        if (eraserMode === 'wholeStroke') {
          removeStrokeAt(coords.x, coords.y);
        } else {
          const newShapes = partialEraseImmutable(
            shapes,
            coords.x,
            coords.y,
            eraserSize
          );
          if (newShapes !== shapes) {
            dispatch(setShapes(newShapes));
          }
        }
      }
    }
  }

  function onPointerMove(e) {
    if (!pointers.current.has(e.pointerId)) return;
    pointers.current.set(e.pointerId, e);

    // Two-pointer pinch-zoom
    if (pointers.current.size === 2) {
      const [p1, p2] = [...pointers.current.values()];
      const dist = Math.hypot(p2.clientX - p1.clientX, p2.clientY - p1.clientY);
      const mid = {
        x: (p1.clientX + p2.clientX) / 2,
        y: (p1.clientY + p2.clientY) / 2
      };

      let newScale = initialScale.current * (dist / initialDistance.current);
      newScale = Math.max(0.5, Math.min(4, newScale));

      const dx = mid.x - initialMid.current.x;
      const dy = mid.y - initialMid.current.y;

      dispatch(
        setCanvasTransform({
          offsetX: startOffset.current.x + dx,
          offsetY: startOffset.current.y + dy,
          scale: newScale
        })
      );
      return;
    }

    // Single pointer
    const coords = screenToLocal(e.clientX, e.clientY);
    dispatch(setPointerPosition(coords));

    // Dragging an object
    if (draggingObjectId != null && toolMode === 'objects') {
      const obj = objects[draggingObjectId];
      const newX = coords.x - dragOffsetRef.current.x;
      const newY = coords.y - dragOffsetRef.current.y;

      if (containerModeEnabled) {
        const hypothetical = { ...obj, x: newX, y: newY };
        for (let i = 0; i < objects.length; i++) {
          if (i === draggingObjectId) continue;
          const other = objects[i];
          if (canStackContainers(hypothetical, other)) {
            // [FIXED] Could highlight overlap or do something
            // console.log(`Overlap with container ${other.id}`);
          }
        }
      }

      const updated = [...objects];
      updated[draggingObjectId] = {
        ...updated[draggingObjectId],
        x: newX,
        y: newY
      };
      dispatch(setObjects(updated));
      return;
    }

    // Painting
    if (isPainting && toolMode === 'paint') {
      if (straightLineActive && paintPointsQueue.current.length > 0) {
        const start = paintPointsQueue.current[0];
        paintPointsQueue.current = [start, coords];
      } else {
        paintPointsQueue.current.push(coords);
      }

      // Broadcast partial stroke
      if (socket && projectId) {
        socket.emit('partialStroke', {
          projectId,
          userId: user?.id,
          type: 'paint',
          brushType,
          brushColor,
          size: brushSize,
          opacity,
          gradient,
          pattern,
          // Only the most recent point
          segment: [coords]
        });
      }
    }

    // Measuring
    else if (isMeasuring && toolMode === 'measure') {
      if (socket && projectId && measureStartCoord.current) {
        socket.emit('partialStroke', {
          projectId,
          userId: user?.id,
          type: 'measure',
          color: '#000',
          size: 2,
          start: measureStartCoord.current,
          end: coords
        });
      }
    }

    // Panning
    else if (isPanning && toolMode === 'dragSelect') {
      const dx = e.clientX - panStart.current.x;
      const dy = e.clientY - panStart.current.y;
      dispatch(
        setCanvasTransform({
          offsetX: initOffset.current.x + dx,
          offsetY: initOffset.current.y + dy,
          scale
        })
      );
    }

    // Erasing partial
    else if (isErasing && toolMode === 'eraser' && eraserMode === 'partial') {
      const newShapes = partialEraseImmutable(shapes, coords.x, coords.y, eraserSize);
      if (newShapes !== shapes) {
        dispatch(setShapes(newShapes));
      }
    }

    // Send cursor movement event
    if (socket && projectId) {
      const c2 = screenToLocal(e.clientX, e.clientY);
      socket.emit('cursorMove', {
        projectId,
        userId: user?.id || 'guest',
        username: user?.username || 'Guest',
        color: user?.cursorColor || '#ff00ff',
        x: c2.x,
        y: c2.y,
        tool: toolMode,
        chatStatus: 'idle'
      });
    }
  }

  function onPointerUp(e) {
    if (pointers.current.has(e.pointerId)) {
      try {
        e.target.releasePointerCapture?.(e.pointerId);
      } catch {}
      pointers.current.delete(e.pointerId);
    }

    // Finish dragging object
    if (draggingObjectId != null && toolMode === 'objects') {
      setDraggingObjectId(null);
    }

    // Finish painting
    if (isPainting && toolMode === 'paint' && pointers.current.size === 0) {
      setIsPainting(false);
      if (paintPointsQueue.current.length > 1) {
        const finalPoints = [...paintPointsQueue.current];
        paintPointsQueue.current = [];

        dispatch(
          finalizeStroke({
            brushType,
            color: brushColor,
            size: brushSize,
            opacity,
            gradient,
            pattern,
            points: finalPoints
          })
        );
        dispatch(
          addPaintStroke({
            type: 'paint',
            brushType,
            color: brushColor,
            size: brushSize,
            opacity,
            gradient,
            pattern,
            points: finalPoints
          })
        );

        if (socket && projectId) {
          socket.emit('finalStroke', {
            projectId,
            userId: user?.id,
            stroke: {
              type: 'paint',
              brushType,
              color: brushColor,
              size: brushSize,
              opacity,
              gradient,
              pattern,
              points: finalPoints
            }
          });
        }

        if (user?.id && projectId) {
          axiosInstance
            .post('/painting/final-stroke', {
              projectId,
              strokeData: {
                brushType,
                color: brushColor,
                size: brushSize,
                opacity,
                gradient,
                pattern,
                points: finalPoints
              }
            })
            .catch((err) => {
              console.error(err);
              toast.error('Error saving final stroke to server.');
            });
        }
      } else {
        paintPointsQueue.current = [];
      }
    }

    // Finish measuring
    if (isMeasuring && toolMode === 'measure' && pointers.current.size === 0) {
      setIsMeasuring(false);
      if (measureStartCoord.current) {
        const endCoords = screenToLocal(e.clientX, e.clientY);
        const startCoords = measureStartCoord.current;
        const dist = Math.hypot(
          endCoords.x - startCoords.x,
          endCoords.y - startCoords.y
        );

        dispatch(
          addPaintStroke({
            type: 'measure',
            points: [startCoords, endCoords],
            distance: dist,
            color: '#000',
            size: 2,
            unit: projectUnit
          })
        );

        if (socket && projectId) {
          socket.emit('finalStroke', {
            projectId,
            userId: user?.id,
            stroke: {
              type: 'measure',
              points: [startCoords, endCoords],
              distance: dist,
              color: '#000',
              size: 2,
              unit: projectUnit
            }
          });
        }

        if (user?.id && projectId) {
          axiosInstance
            .post('/painting/final-stroke', {
              projectId,
              strokeData: {
                type: 'measure',
                points: [startCoords, endCoords],
                distance: dist,
                color: '#000',
                size: 2,
                unit: projectUnit
              }
            })
            .catch((err) => {
              console.error(err);
              toast.error('Error saving measure line to server.');
            });
        }

        measureStartCoord.current = null;
      }
    }

    // Finish erasing
    if (isErasing && toolMode === 'eraser' && pointers.current.size === 0) {
      setIsErasing(false);
    }

    // Finish panning
    if (isPanning && pointers.current.size === 0) {
      setIsPanning(false);
    }
  }

  function screenToLocal(clientX, clientY) {
    const rect = containerRef.current.getBoundingClientRect();
    let lx = (clientX - rect.left - offsetX) / scale;
    let ly = (clientY - rect.top - offsetY) / scale;

    if (snapToGrid) {
      lx = Math.round(lx / gridSize) * gridSize;
      ly = Math.round(ly / gridSize) * gridSize;
    }
    return { x: lx, y: ly };
  }

  function removeStrokeAt(x, y) {
    const threshold = 10;
    for (let i = 0; i < shapes.length; i++) {
      const s = shapes[i];
      if (s.type !== 'paint') continue;
      const pts = s.points || [];
      for (let j = 0; j < pts.length - 1; j++) {
        const p1 = pts[j];
        const p2 = pts[j + 1];
        const dist = pointLineSegmentDistance({ x, y }, p1, p2);
        if (dist < threshold) {
          dispatch(removeShape(i));
          return;
        }
      }
    }
  }

  // [FIXED] partialErase: handle single-point strokes gracefully
  function partialEraseImmutable(oldShapes, cx, cy, eraseRadius) {
    let changed = false;
    const newShapes = [];

    for (let i = 0; i < oldShapes.length; i++) {
      const s = oldShapes[i];
      if (s.type !== 'paint') {
        newShapes.push(s);
        continue;
      }

      const pts = s.points || [];
      let newSubstrokes = [];
      let currentSub = [];

      for (let j = 0; j < pts.length; j++) {
        const p = pts[j];
        const dx = p.x - cx;
        const dy = p.y - cy;
        const dist = Math.hypot(dx, dy);

        if (dist <= eraseRadius) {
          if (currentSub.length >= 2) {
            newSubstrokes.push({
              ...s,
              points: [...currentSub]
            });
          }
          currentSub = [];
          changed = true;
        } else {
          currentSub.push(p);
        }
      }

      if (currentSub.length >= 2) {
        newSubstrokes.push({
          ...s,
          points: [...currentSub]
        });
      } else if (currentSub.length === 1) {
        // If there's a single leftover point, we can decide to keep or discard it.
        // For simplicity, discard if partial eraser removed part of stroke.
        changed = true;
      }

      if (newSubstrokes.length === 0) {
        changed = true;
      } else if (
        newSubstrokes.length === 1 &&
        newSubstrokes[0].points.length === pts.length
      ) {
        newShapes.push(newSubstrokes[0]);
      } else {
        newShapes.push(...newSubstrokes);
      }
    }

    return changed ? newShapes : oldShapes;
  }

  function pointLineSegmentDistance(p, a, b) {
    const abx = b.x - a.x;
    const aby = b.y - a.y;
    const apx = p.x - a.x;
    const apy = p.y - a.y;
    const abLenSq = abx * abx + aby * aby;
    if (abLenSq === 0) {
      return Math.hypot(p.x - a.x, p.y - a.y);
    }
    let t = (apx * abx + apy * aby) / abLenSq;
    t = Math.max(0, Math.min(1, t));
    const projx = a.x + t * abx;
    const projy = a.y + t * aby;
    return Math.hypot(p.x - projx, p.y - projy);
  }

  function drawPaintStroke(ctx, stroke) {
    const pts = stroke.points || [];
    if (pts.length < 2) return;

    ctx.save();
    ctx.globalAlpha = stroke.opacity || 1.0;
    ctx.lineCap = 'round';
    ctx.lineJoin = 'round';
    ctx.lineWidth = stroke.size || 2;

    let strokeStyle = stroke.color || '#000';
    if (stroke.gradient && pts.length >= 2) {
      const first = pts[0];
      const last = pts[pts.length - 1];
      const grad = ctx.createLinearGradient(first.x, first.y, last.x, last.y);
      grad.addColorStop(0, stroke.color || '#000');
      grad.addColorStop(1, 'rgba(255,255,255,0.3)');
      strokeStyle = grad;
    }
    ctx.strokeStyle = strokeStyle;

    if (stroke.brushType && stroke.brushType.startsWith('stamp')) {
      pts.forEach((pt) => {
        ctx.beginPath();
        ctx.arc(pt.x, pt.y, stroke.size / 2, 0, Math.PI * 2);
        ctx.fillStyle = stroke.color || '#00f';
        ctx.fill();
      });
      ctx.restore();
      return;
    }

    ctx.beginPath();
    ctx.moveTo(pts[0].x, pts[0].y);
    for (let i = 1; i < pts.length - 2; i++) {
      const xc = (pts[i].x + pts[i + 1].x) / 2;
      const yc = (pts[i].y + pts[i + 1].y) / 2;
      ctx.quadraticCurveTo(pts[i].x, pts[i].y, xc, yc);
    }
    const secondLast = pts[pts.length - 2];
    const last = pts[pts.length - 1];
    ctx.quadraticCurveTo(secondLast.x, secondLast.y, last.x, last.y);

    ctx.stroke();
    ctx.restore();
  }

  function drawMeasureLine(ctx, line) {
    const pts = line.points || [];
    if (pts.length < 2) return;

    ctx.save();
    ctx.lineCap = 'round';
    ctx.lineJoin = 'round';
    ctx.strokeStyle = line.color || '#000';
    ctx.lineWidth = line.size || 2;

    ctx.beginPath();
    ctx.moveTo(pts[0].x, pts[0].y);
    ctx.lineTo(pts[1].x, pts[1].y);
    ctx.stroke();

    ctx.restore();

    if (line.distance !== undefined) {
      const [p1, p2] = pts;
      const midX = (p1.x + p2.x) / 2;
      const midY = (p1.y + p2.y) / 2;
      ctx.save();
      ctx.fillStyle = '#d00';
      ctx.font = '14px sans-serif';
      ctx.fillText(
        `${line.distance.toFixed(1)} ${line.unit || projectUnit}`,
        midX + 5,
        midY - 5
      );
      ctx.restore();
    }
  }

  // [FIXED] unify SHIFT or custom key for straight line
  function handleKeyDown(e) {
    if (
      e.key === 'Shift' ||
      e.key.toLowerCase() === 'x' ||
      e.key.toLowerCase() === customStraightLineKey
    ) {
      setStraightLineActive(true);
    }
  }

  function handleKeyUp(e) {
    if (
      e.key === 'Shift' ||
      e.key.toLowerCase() === 'x' ||
      e.key.toLowerCase() === customStraightLineKey
    ) {
      setStraightLineActive(false);
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [customStraightLineKey]);

  // Mouse wheel zoom
  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    function onWheel(e) {
      e.preventDefault();
      const rect = container.getBoundingClientRect();
      const pointerX = e.clientX - rect.left;
      const pointerY = e.clientY - rect.top;
      const zoomFactor = 0.1;
      const direction = e.deltaY < 0 ? 1 + zoomFactor : 1 - zoomFactor;

      let newScale = scale * direction;
      newScale = Math.max(0.5, Math.min(4, newScale));

      const ratio = newScale / scale;
      const newOffsetX = offsetX - pointerX * (ratio - 1);
      const newOffsetY = offsetY - pointerY * (ratio - 1);

      dispatch(
        setCanvasTransform({
          offsetX: newOffsetX,
          offsetY: newOffsetY,
          scale: newScale
        })
      );
    }

    container.addEventListener('wheel', onWheel, { passive: false });
    return () => {
      container.removeEventListener('wheel', onWheel);
    };
  }, [dispatch, offsetX, offsetY, scale]);

  return (
    <div
      ref={containerRef}
      className="garden-canvas"
      style={{
        position: 'relative',
        width: '100%',
        height: '100%',
        backgroundColor: canvasBg,
        overflow: 'hidden'
      }}
      onPointerDown={onPointerDown}
      onPointerMove={onPointerMove}
      onPointerUp={onPointerUp}
      onPointerCancel={onPointerUp}
    >
      <div
        ref={gridRef}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          pointerEvents: 'none',
          zIndex: 1
        }}
      />
      <div
        style={{
          position: 'absolute',
          zIndex: 2,
          border: '2px dashed #888',
          left: offsetX,
          top: offsetY,
          width: baseProjectPxWidth * scale,
          height: baseProjectPxHeight * scale,
          pointerEvents: 'none'
        }}
      />
      <div
        style={{
          position: 'absolute',
          zIndex: 3,
          left: offsetX,
          top: offsetY,
          width: baseProjectPxWidth,
          height: baseProjectPxHeight,
          transform: `scale(${scale})`,
          transformOrigin: 'top left',
          pointerEvents: 'none'
        }}
      >
        <canvas
          ref={paintCanvasRef}
          width={baseProjectPxWidth}
          height={baseProjectPxHeight}
          style={{
            position: 'absolute',
            left: 0,
            top: 0
          }}
        />
        <canvas
          ref={measureCanvasRef}
          width={baseProjectPxWidth}
          height={baseProjectPxHeight}
          style={{
            position: 'absolute',
            left: 0,
            top: 0
          }}
        />
        {isLayerVisible('objects') &&
          objects.map((obj) => (
            <div
              key={obj.id}
              style={{
                position: 'absolute',
                left: obj.x,
                top: obj.y,
                width: obj.width || 50,
                height: obj.height || 50,
                backgroundColor: '#ccc',
                border: '1px solid #333'
              }}
            >
              {obj.name}
            </div>
          ))}
      </div>
    </div>
  );
}
