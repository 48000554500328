// File: client/src/store/collabSlice.js

import { createSlice } from '@reduxjs/toolkit';

const collabSlice = createSlice({
name: 'collab',
initialState: {
cursors: {}
},
reducers: {

setCollaboratorCursor(state, action) {
const { userId, x, y, username, color, tool, chatStatus } = action.payload;
state.cursors[userId] = {
x,
y,
username,
color,
tool,
chatStatus

};
},

removeCollaborator(state, action) {
const { userId } = action.payload;
delete state.cursors[userId];
}
}
});

export const { setCollaboratorCursor, removeCollaborator } = collabSlice.actions;
export default collabSlice.reducer;
