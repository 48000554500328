// File: client/src/index.js
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './store/index.js';
import App from './App.js';
import './styles/main.css';

import { ChakraProvider, extendTheme, ColorModeScript } from '@chakra-ui/react';
import themeConfig from './themeConfig.js';

import { Toaster } from 'react-hot-toast';

const customTheme = extendTheme(themeConfig);

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
<React.StrictMode>
<Provider store={store}>
<ChakraProvider theme={customTheme}>
<ColorModeScript initialColorMode={customTheme.config.initialColorMode} />
<App />
<Toaster
position="top-right"
toastOptions={{
duration: 4000,
style: {
fontSize: '0.9rem'
}
}}
/>
</ChakraProvider>
</Provider>
</React.StrictMode>

);
