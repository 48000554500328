// File: client/src/themeConfig.js

const themeConfig = {
config: {
initialColorMode: 'light',
useSystemColorMode: false
},
fonts: {
heading: 'Roboto, sans-serif',
body: 'Roboto, sans-serif'
},
colors: {
brand: {
50: '#e3f9e5',
100: '#c1eac5',
200: '#a3d9a5',
300: '#7bc47f',
400: '#57ae5b',
500: '#3f9142',
600: '#2f8132',
700: '#207227',
800: '#0e5814',
900: '#05400a'

}
},
styles: {
global: (props) => ({
body: {
bg: props.colorMode === 'dark' ? 'gray.800' : 'gray.50',
color: props.colorMode === 'dark' ? 'gray.50' : 'gray.800',
margin: 0,
padding: 0
}
})
}
};

export default themeConfig;
