// File: client/src/store/userSettingsSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../api/axiosInstance.js';

// Existing async thunks for user preferences:
export const fetchKeyBinds = createAsyncThunk(
  'userSettings/fetchKeyBinds',
  async (_, { rejectWithValue }) => {
    try {
      const res = await axios.get('/userSettings/keybinds');
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.error || 'Failed to fetch keybinds.');
    }
  }
);

export const updateKeyBinds = createAsyncThunk(
  'userSettings/updateKeyBinds',
  async (keyBinds, { rejectWithValue }) => {
    try {
      const res = await axios.put('/userSettings/keybinds', keyBinds);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.error || 'Failed to update keybinds.');
    }
  }
);

export const updateUserPreferences = createAsyncThunk(
  'userSettings/updateUserPreferences',
  async (preferences, { rejectWithValue }) => {
    try {
      const res = await axios.put('/userSettings/preferences', preferences);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.error || 'Failed to update user preferences.');
    }
  }
);

// Slice
const userSettingsSlice = createSlice({
  name: 'userSettings',
  initialState: {
    keyBinds: {},
    darkMode: false,
    showTooltips: true,
    maxCanvasSize: { width: 1000, height: 1000 },
    // NEW: eraser mode, either 'wholeStroke' or 'partial'
    eraserMode: 'wholeStroke',

    loading: false,
    error: null
  },
  reducers: {
    toggleDarkMode(state) {
      state.darkMode = !state.darkMode;
    },
    setDarkMode(state, action) {
      state.darkMode = action.payload;
    },
    setShowTooltips(state, action) {
      state.showTooltips = action.payload;
    },
    setMaxCanvasSize(state, action) {
      state.maxCanvasSize = action.payload;
    },

    // Add a new reducer to set eraser mode
    setEraserMode(state, action) {
      // e.g., 'wholeStroke' or 'partial'
      state.eraserMode = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      // fetchKeyBinds
      .addCase(fetchKeyBinds.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchKeyBinds.fulfilled, (state, action) => {
        state.loading = false;
        state.keyBinds = action.payload;
      })
      .addCase(fetchKeyBinds.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // updateKeyBinds
      .addCase(updateKeyBinds.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateKeyBinds.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateKeyBinds.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // updateUserPreferences
      .addCase(updateUserPreferences.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateUserPreferences.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.darkMode !== undefined) {
          state.darkMode = action.payload.darkMode;
        }
        if (action.payload.showTooltips !== undefined) {
          state.showTooltips = action.payload.showTooltips;
        }
        if (action.payload.maxCanvasSize) {
          state.maxCanvasSize = action.payload.maxCanvasSize;
        }
      })
      .addCase(updateUserPreferences.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export const {
  toggleDarkMode,
  setDarkMode,
  setShowTooltips,
  setMaxCanvasSize,
  setEraserMode
} = userSettingsSlice.actions;

export default userSettingsSlice.reducer;
