// File: client/src/store/gardenSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  toolMode: 'none',
  objects: [], // On-canvas objects (plants, structures, etc.) with bounding box data
  shapes: [],  // Painted strokes, measuring lines, etc.

  canvasTransform: {
    offsetX: 0,
    offsetY: 0,
    scale: 1
  },
  layers: [
    { id: 'painting', name: 'Painting Layer', visible: true, orderIndex: 0 },
    { id: 'measuring', name: 'Measuring Layer', visible: true, orderIndex: 1 },
    { id: 'objects', name: 'Objects Layer', visible: true, orderIndex: 2 }
  ],
  name: '',
  width: 50,
  height: 50,
  unit: 'feet',

  snapToGrid: false,
  gridSize: 20, // size in px or in "canvas space" for snapping

  pointerPosition: { x: 0, y: 0 }
};

const gardenSlice = createSlice({
  name: 'garden',
  initialState,
  reducers: {
    setToolMode(state, action) {
      state.toolMode = action.payload;
    },

    applyCanvasUpdate(state, action) {
      // Intentionally left blank for now
    },

    setCanvasTransform(state, action) {
      const { offsetX, offsetY, scale } = action.payload;
      state.canvasTransform.offsetX = offsetX;
      state.canvasTransform.offsetY = offsetY;
      state.canvasTransform.scale = scale;
    },

    setObjects(state, action) {
      state.objects = action.payload;
    },

    setShapes(state, action) {
      state.shapes = action.payload;
    },

    addPaintStroke(state, action) {
      state.shapes.push(action.payload);
    },

    removeShape(state, action) {
      const idx = action.payload;
      if (idx >= 0 && idx < state.shapes.length) {
        state.shapes.splice(idx, 1);
      }
    },

    loadDesignData(state, action) {
      const {
        objects,
        shapes,
        canvasTransform,
        name,
        width,
        height,
        unit,
        layers,
        snapToGrid,
        gridSize
      } = action.payload;

      if (Array.isArray(objects)) {
        state.objects = objects;
      }
      if (Array.isArray(shapes)) {
        state.shapes = shapes;
      }
      if (canvasTransform && typeof canvasTransform === 'object') {
        state.canvasTransform = {
          ...state.canvasTransform,
          ...canvasTransform
        };
      }
      if (typeof name === 'string') {
        state.name = name;
      }
      if (typeof width === 'number') {
        state.width = width;
      }
      if (typeof height === 'number') {
        state.height = height;
      }
      if (typeof unit === 'string') {
        state.unit = unit;
      }
      if (Array.isArray(layers)) {
        state.layers = layers;
      }
      if (typeof snapToGrid === 'boolean') {
        state.snapToGrid = snapToGrid;
      }
      if (typeof gridSize === 'number') {
        state.gridSize = gridSize;
      }
    },

    setLayerVisibility(state, action) {
      const { layerId, visible } = action.payload;
      const layer = state.layers.find((l) => l.id === layerId);
      if (layer) {
        layer.visible = visible;
      }
    },

    renameLayer(state, action) {
      const { layerId, newName } = action.payload;
      const layer = state.layers.find((l) => l.id === layerId);
      if (layer) {
        layer.name = newName;
      }
    },

    reorderLayer(state, action) {
      const { layerId, direction } = action.payload; // direction = 'up' or 'down'
      const index = state.layers.findIndex((l) => l.id === layerId);
      if (index < 0) return;

      const targetLayer = state.layers[index];
      if (direction === 'up' && index > 0) {
        const aboveLayer = state.layers[index - 1];
        const tempIndex = aboveLayer.orderIndex;
        aboveLayer.orderIndex = targetLayer.orderIndex;
        targetLayer.orderIndex = tempIndex;
      } else if (direction === 'down' && index < state.layers.length - 1) {
        const belowLayer = state.layers[index + 1];
        const tempIndex = belowLayer.orderIndex;
        belowLayer.orderIndex = targetLayer.orderIndex;
        targetLayer.orderIndex = tempIndex;
      }
      // Optionally ensure no negative orderIndex occurs
      state.layers.sort((a, b) => a.orderIndex - b.orderIndex);
    },

    toggleSnapToGrid(state, action) {
      state.snapToGrid = action.payload;
    },

    setGridSize(state, action) {
      state.gridSize = action.payload;
    },

    setPointerPosition(state, action) {
      state.pointerPosition = {
        x: action.payload.x,
        y: action.payload.y
      };
    }
  }
});

export const {
  setToolMode,
  applyCanvasUpdate,
  setCanvasTransform,
  setObjects,
  setShapes,
  addPaintStroke,
  removeShape,
  loadDesignData,
  setLayerVisibility,
  renameLayer,
  reorderLayer,
  toggleSnapToGrid,
  setGridSize,
  setPointerPosition
} = gardenSlice.actions;

export default gardenSlice.reducer;
