// File: client/src/components/UserProfile/UserProfilePage.js

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from '../../api/axiosInstance.js';
import { fetchUserProfile } from '../../store/authSlice.js';
import { fetchKeyBinds, updateKeyBinds } from '../../store/userSettingsSlice.js';
import { Box, Heading, FormControl, FormLabel, Input, Button, Text } from '@chakra-ui/react';
import AchievementsPanel from './AchievementsPanel.js';

function UserProfilePage() {
  const dispatch = useDispatch();
  const { user, loading: authLoading, error: authError } = useSelector((state) => state.auth);
  const { keyBinds, loading: keybindLoading, error: keybindError } = useSelector((state) => state.userSettings);

  const [formData, setFormData] = useState({
    username: '',
    email: '',
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: ''
  });

  const [keybindsLocal, setKeybindsLocal] = useState({});
  const [message, setMessage] = useState('');

  useEffect(() => {
    dispatch(fetchUserProfile());
    dispatch(fetchKeyBinds());
  }, [dispatch]);

  useEffect(() => {
    if (user) {
      setFormData({
        username: user.username || '',
        email: user.email || '',
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: ''
      });
    }
  }, [user]);

  useEffect(() => {
    if (keyBinds) {
      setKeybindsLocal({ ...keyBinds });
    }
  }, [keyBinds]);

  const handleProfileSubmit = async (e) => {
    e.preventDefault();
    setMessage('');

    // If user wants to set a new password, enforce min length 8
    if (formData.newPassword) {
      if (formData.newPassword.length < 8) {
        setMessage('New password must be at least 8 characters long.');
        return;
      }
      if (formData.newPassword !== formData.confirmNewPassword) {
        setMessage('New password and confirmation do not match.');
        return;
      }
    }
    try {
      const res = await axios.put('/auth/profile', {
        username: formData.username,
        email: formData.email,
        currentPassword: formData.currentPassword,
        newPassword: formData.newPassword
      });
      if (res.data.error) {
        setMessage(res.data.error);
      } else {
        setMessage('Profile updated successfully.');
        dispatch(fetchUserProfile());
      }
    } catch (err) {
      console.error('Profile update error:', err);
      setMessage(err.response?.data?.error || 'Profile update failed.');
    }
  };

  const handleKeybindSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    try {
      await dispatch(updateKeyBinds(keybindsLocal)).unwrap();
      setMessage('Keybinds updated successfully.');
    } catch (err) {
      setMessage(err || 'Failed to update keybinds.');
    }
  };

  return (
    <Box maxW="600px" mx="auto" mt="3rem">
      <Heading as="h2" mb={4}>My Profile</Heading>
      {message && <Text color="green.600" mb={4}>{message}</Text>}
      {authError && <Text color="red.500" mb={4}>{authError}</Text>}

      {/* Basic profile update */}
      <form onSubmit={handleProfileSubmit}>
        <FormControl mb={3}>
          <FormLabel>Username</FormLabel>
          <Input
            value={formData.username}
            onChange={(e) => setFormData({ ...formData, username: e.target.value })}
          />
        </FormControl>
        <FormControl mb={3}>
          <FormLabel>Email</FormLabel>
          <Input
            type="email"
            value={formData.email}
            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
          />
        </FormControl>

        <Heading as="h3" size="sm" mt={4} mb={2}>Change Password</Heading>
        <FormControl mb={3}>
          <FormLabel>Current Password</FormLabel>
          <Input
            type="password"
            value={formData.currentPassword}
            onChange={(e) => setFormData({ ...formData, currentPassword: e.target.value })}
          />
        </FormControl>
        <FormControl mb={3}>
          <FormLabel>New Password</FormLabel>
          <Input
            type="password"
            value={formData.newPassword}
            onChange={(e) => setFormData({ ...formData, newPassword: e.target.value })}
          />
        </FormControl>
        <FormControl mb={3}>
          <FormLabel>Confirm New Password</FormLabel>
          <Input
            type="password"
            value={formData.confirmNewPassword}
            onChange={(e) => setFormData({ ...formData, confirmNewPassword: e.target.value })}
          />
        </FormControl>

        <Button type="submit" colorScheme="blue" isLoading={authLoading}>
          Update Profile
        </Button>
      </form>

      {/* Keybind Settings */}
      <Box mt={8} p={4} borderWidth="1px" borderRadius="md">
        <Heading as="h3" size="sm" mb={4}>Keybind Settings</Heading>
        {keybindError && <Text color="red.500" mb={2}>{keybindError}</Text>}

        <form onSubmit={handleKeybindSubmit}>
          <FormControl mb={3}>
            <FormLabel>Straight Line Mode Key</FormLabel>
            <Input
              value={keybindsLocal.straightLine || ''}
              onChange={(e) => setKeybindsLocal({ ...keybindsLocal, straightLine: e.target.value })}
            />
          </FormControl>
          <FormControl mb={3}>
            <FormLabel>Eraser Tool Key</FormLabel>
            <Input
              value={keybindsLocal.eraser || ''}
              onChange={(e) => setKeybindsLocal({ ...keybindsLocal, eraser: e.target.value })}
            />
          </FormControl>

          <FormControl mb={3}>
            <FormLabel>Paint Brush Key</FormLabel>
            <Input
              value={keybindsLocal.paintBrush || ''}
              onChange={(e) => setKeybindsLocal({ ...keybindsLocal, paintBrush: e.target.value })}
            />
          </FormControl>

          <Button type="submit" colorScheme="blue" isLoading={keybindLoading}>
            Save Keybinds
          </Button>
        </form>
      </Box>

      {/* Achievements Panel */}
      <AchievementsPanel />
    </Box>
  );
}

export default UserProfilePage;
