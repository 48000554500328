// File: client/src/components/ProtectedRoute.js

import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

export default function ProtectedRoute({ children, adminOnly = false }) {

const { user, loading, initialized } = useSelector(state => state.auth);

if (!initialized) {
return <div style={{ textAlign: 'center', marginTop: '2rem' }}>Initializing...</div>;
}

if (loading) {
return <div>Loading user...</div>;
}

if (!user) {
return <Navigate to="/login" replace />;
}

if (adminOnly && !user.is_admin) {
return <Navigate to="/dashboard" replace />;
}

return children;
}
