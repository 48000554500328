// File: client/src/store/index.js
import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authSlice.js';
import userSettingsReducer from './userSettingsSlice.js';
import gardenReducer from './gardenSlice.js';
import projectsReducer from './projectsSlice.js';
import helpReducer from './helpSlice.js';
import calendarReducer from './calendarSlice.js';
import customObjectsReducer from './customObjectsSlice.js';

import globalConfigReducer from './globalConfigSlice.js';
import paintingReducer from './paintingSlice.js';
import collabReducer from './collabSlice.js';

const store = configureStore({
reducer: {
auth: authReducer,
userSettings: userSettingsReducer,
garden: gardenReducer,
projects: projectsReducer,
help: helpReducer,
calendar: calendarReducer,
customObjects: customObjectsReducer,
globalConfig: globalConfigReducer,
painting: paintingReducer,
collab: collabReducer
}
});

export default store;
