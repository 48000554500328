// File: client/src/components/Calendar/EventsCalendar.js
import React, { useEffect, useState } from 'react';
import axios from '../../api/axiosInstance.js';
import { Box, Heading, Spinner, UnorderedList, ListItem } from '@chakra-ui/react';

function EventsCalendar() {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);

  const loadEvents = async () => {
    setLoading(true);
    try {
      const res = await axios.get('/calendar');
      setEvents(res.data);
    } catch (err) {
      console.error('EventsCalendar Error:', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadEvents();
  }, []);

  return (
    <Box p={4}>
      <Heading as="h2" mb={4}>
        Calendar Events
      </Heading>
      {loading ? (
        <Spinner />
      ) : (
        <UnorderedList>
          {events.map((ev) => (
            <ListItem key={ev.id}>
              <strong>{ev.title}</strong> from {ev.start_time} to {ev.end_time}
            </ListItem>
          ))}
        </UnorderedList>
      )}
    </Box>
  );
}

export default EventsCalendar;
