// File: client/src/store/helpSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../api/axiosInstance.js';

export const fetchHelpTopics = createAsyncThunk('help/fetch', async (search, { rejectWithValue }) => {
try {
const res = await axios.get('/help', { params: { search } });

return res.data;
} catch (err) {
return rejectWithValue(err.response?.data?.error || 'Failed to fetch help topics');
}
});

const helpSlice = createSlice({
name: 'help',
initialState: {
topics: [],
loading: false,
error: null
},
reducers: {},
extraReducers: (builder) => {
builder
.addCase(fetchHelpTopics.pending, (state) => { state.loading = true; state.error = null; })
.addCase(fetchHelpTopics.fulfilled, (state, action) => {
state.loading = false;
state.topics = action.payload;

})
.addCase(fetchHelpTopics.rejected, (state, action) => {
state.loading = false;
state.error = action.payload;
});
}
});

export default helpSlice.reducer;