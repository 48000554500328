// File: client/src/api/axiosInstance.js
import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL || '/api',
  withCredentials: true
});

let csrfToken = null;
let csrfTokenTimestamp = 0;
const TOKEN_MAX_AGE = 10 * 60_000; // 10 minutes

async function fetchCsrfToken() {
  const resp = await axiosInstance.get('/csrf');
  csrfToken = resp.data.csrfToken;
  csrfTokenTimestamp = Date.now();
}

axiosInstance.interceptors.request.use(
  async (config) => {
    const needsCsrf =
      config.method === 'post' ||
      config.method === 'put' ||
      config.method === 'delete' ||
      config.method === 'patch';

    if (needsCsrf) {
      const tooOld = Date.now() - csrfTokenTimestamp > TOKEN_MAX_AGE;
      if (!csrfToken || tooOld) {
        await fetchCsrfToken();
      }
      config.headers['X-CSRF-Token'] = csrfToken;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (
      error.response &&
      error.response.status === 403 &&
      !error.config.__retry
    ) {
      error.config.__retry = true;
      await fetchCsrfToken();
      error.config.headers['X-CSRF-Token'] = csrfToken;
      return axiosInstance(error.config);
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
